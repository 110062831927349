// PAGES IMAGES
import logoTransparent from '../assets/logo/logo-transparent.png';
import logo from '../assets/logo/logo.png';
import boysPlayingChess from '../assets/chess/boys-playing-chess.png';
import happyBoys from '../assets/whatwedo/happy-boys.jpeg';
import insideTheLibrary from '../assets/about/inside-the-library.jpg';
import workingTogether from '../assets/about/working-together.jpeg';
import happyChildren from '../assets/about/happyChildren.JPG';
import kiberaKidBlack from '../assets/home/kibera-kid-black.png';
import cover from '../assets/home/cover-photo.jpeg'

// WHAT WE DO - Computer class
import computerClass1 from '../assets/whatwedo/computerClass/IMG_1450.jpg'
import computerClass2 from '../assets/whatwedo/computerClass/IMG_1452.jpg';
import computerClass3 from '../assets/whatwedo/computerClass/IMG_1453.jpg';
import computerClass4 from '../assets/whatwedo/computerClass/IMG_1465.jpg';
import computerClass5 from '../assets/whatwedo/computerClass/IMG_1473.jpg';
import computerClass6 from '../assets/whatwedo/computerClass/IMG_1478.jpg';
import computerClass7 from '../assets/whatwedo/computerClass/IMG_1483.jpg';
import computerClass8 from '../assets/whatwedo/computerClass/IMG_1490.jpg';
import computerClass9 from '../assets/whatwedo/computerClass/computer-class-1.jpg';

// WHAT WE DO - Chess club
import chessClub1 from '../assets/whatwedo/chessClub/IMG_7987.jpeg';
import chessClub2 from '../assets/whatwedo/chessClub/boys-playing-chess-3-reduced.jpeg';
import chessClub3 from '../assets/whatwedo/chessClub/kids-playing-chess-2.jpeg';
import chessClub4 from '../assets/whatwedo/chessClub/playingChess.jpeg';
import chessClub5 from '../assets/whatwedo/chessClub/kids-playing-chess-1-780x420-reduced.jpeg';

// WHAT WE DO - Study group
import studyGroup1 from '../assets/whatwedo/studyGroup/IMG_1403.jpeg';
import studyGroup2 from '../assets/whatwedo/studyGroup/IMG_1404.jpeg';
import studyGroup3 from '../assets/whatwedo/studyGroup/IMG_1405.jpeg';
import studyGroup4 from '../assets/whatwedo/studyGroup/IMG_1406.jpeg';
import studyGroup5 from '../assets/whatwedo/studyGroup/IMG_1412.jpeg';
import studyGroup6 from '../assets/whatwedo/studyGroup/IMG_1419.jpeg';
import studyGroup7 from '../assets/whatwedo/studyGroup/IMG_1426.jpeg';
import studyGroup8 from '../assets/whatwedo/studyGroup/IMG_1431.jpeg';
import studyGroup9 from '../assets/whatwedo/studyGroup/IMG_1435.jpeg';
import studyGroup10 from '../assets/whatwedo/studyGroup/many-studying.jpeg';

// WHAT WE DO - Dance group
import danceGroup1 from '../assets/whatwedo/danceGroup/dance-group-1.jpeg';
import danceGroup2 from '../assets/whatwedo/danceGroup/dance-group-2.jpeg';
import danceGroup3 from '../assets/whatwedo/danceGroup/dance-group-3.jpeg';
import danceGroup4 from '../assets/whatwedo/danceGroup/IMG_6656.jpeg';
import danceGroup5 from '../assets/whatwedo/danceGroup/yo.jpeg';

// WHAT WE DO - KLWF
import klwf1 from '../assets/whatwedo/klwf/382212481_6638771996236780_132086343224290792_n.jpg';
import klwf2 from '../assets/whatwedo/klwf/382236723_6638772479570065_5626379851047552354_n.jpg';
import klwf3 from '../assets/whatwedo/klwf/383079899_6638772229570090_5112142846467368288_n.jpg';
import klwf4 from '../assets/whatwedo/klwf/502nkB7w_400x400.jpeg';
import klwf5 from '../assets/whatwedo/klwf/IMG_1129.jpg';
import klwf6 from '../assets/whatwedo/klwf/IMG_1200.jpeg';
import klwf7 from '../assets/whatwedo/klwf/img_8247.jpg';
import klwf8 from '../assets/whatwedo/klwf/img_8297.jpg';
import klwf9 from '../assets/whatwedo/klwf/img_8299.jpeg';
import klwf10 from '../assets/whatwedo/klwf/img_8300.jpg';
import klwf11 from '../assets/whatwedo/klwf/poster.png';
import klwf12 from '../assets/whatwedo/klwf/whatsapp1.jpeg';
import klwf13 from '../assets/whatwedo/klwf/IMG_1301.JPG';

// WHAT WE DO - The Watoto Academy
import twa1 from '../assets/whatwedo/theWatotoAcademy/classroom.jpg';
import twa2 from '../assets/whatwedo/theWatotoAcademy/sciencelab.png';
import twa3 from '../assets/whatwedo/theWatotoAcademy/fundraising.png';
import twa4 from '../assets/whatwedo/theWatotoAcademy/support.png';
import twa5 from '../assets/whatwedo/theWatotoAcademy/renovating.jpg';
import twa6 from '../assets/whatwedo/theWatotoAcademy/twa-logo.png';
import twa7 from '../assets/whatwedo/theWatotoAcademy/tutored.png';
import twa8 from '../assets/whatwedo/theWatotoAcademy/limited-resources.png';
import twa9 from '../assets/whatwedo/theWatotoAcademy/sponsored.png';

// ABOUT US - General
import team from '../assets/about/team.jpeg'


// ABOUT US - Kibera
import kibera1 from '../assets/about/kibera/whereWeOperate.jpeg';
import kibera2 from '../assets/about/kibera/whoWeAreEngagingWith.jpeg';
import kibera3 from '../assets/about/kibera/lifeOfAKiberaChild.jpeg';
import kibera4 from '../assets/about/kibera/sufferingInSilence.jpeg';
import kibera5 from '../assets/about/kibera/schoolSituation.jpeg';
import kibera6 from '../assets/about/kibera/kiberaWindow.png';

// ABOUT US - Founder
import founder1 from '../assets/about/founder/BrandonAndKids.jpeg';
import founder2 from '../assets/about/founder/BrandonAndKids2.jpeg';
import founder3 from '../assets/about/founder/brandonAndKids3.jpeg';
import founder4 from '../assets/about/founder/BrandonInTanzania.jpeg';
import founder5 from '../assets/about/founder/BrandonInTWL.JPG';
import founder6 from '../assets/about/founder/BrandonAndKids4.JPG';
import founder7 from '../assets/about/founder/Magnus.png';
import founder8 from '../assets/about/founder/MagnusChess.jpg';

// ABOUT US - Publications
import publications1 from '../assets/about/publications/publications1.jpeg'

// ABOUT US - Partners
import partners1 from '../assets/about/partners/partners1.JPG'

// Get in touch - PARTNERS
import opsahlGruppen from '../assets/donate/partners/opsahlgruppen.png'
import skullerudSkole from '../assets/donate/partners/skullerud-logo.png'
import wwqa from '../assets/donate/partners/wwqa.png'
import youcanyole from '../assets/donate/partners/youcanyole.png'
import sos from '../assets/donate/partners/sos.png'

// DONATE
import vippsLogo from '../assets/donate/vipps.png';
import paypalLogo from '../assets/donate/paypal-transparent.png';
import donorBoxLogo from '../assets/donate/donorbox-logo.png';
import scanVipps from '../assets/donate/scanVipps.png';
import kidLooking from '../assets/donate/kidLooking.jpeg';
import stripeLogo from '../assets/donate/stripeLogo.png'

// SPONSORSHIP
import desk from '../assets/sponsorship/deskImg.png'
import lunch from '../assets/sponsorship/lunchImg.png'
import materials from '../assets/sponsorship/materialsImg.png'
import uniform from '../assets/sponsorship/uniformImg.png'

// --------------------------- ARTICLES ---------------------------------- //



/* ---------------------------- INNSAMLING ---------------------- */
import classPhoto from '../assets/innsamling/twa-classphoto.png'
import collection from '../assets/innsamling/innsamling-collection.png'
import collection2 from '../assets/innsamling/innsamling-collection-2.png'


export default {

    // PAGES IMAGES
    logoTransparent, logo, boysPlayingChess, happyBoys, insideTheLibrary, workingTogether, happyChildren, kiberaKidBlack, cover,

    // WHAT WE DO - Computer class
    computerClass1, computerClass2, computerClass3, computerClass4, computerClass5, computerClass6, computerClass7, computerClass8, computerClass9,
    
    // WHAT WE DO - Chess club
    chessClub1, chessClub2, chessClub3, chessClub4, chessClub5,
    
     // WHAT WE DO - Study group
    studyGroup1, studyGroup2, studyGroup3, studyGroup4, studyGroup5, studyGroup6, studyGroup7, studyGroup8, studyGroup9, studyGroup10,
    
    // WHAT WE DO - Dance group
    danceGroup1, danceGroup2, danceGroup3, danceGroup4, danceGroup5,
    
    // WHAT WE DO - KLWF
    klwf1, klwf2, klwf3, klwf4, klwf5, klwf6, klwf7, klwf8, klwf9, klwf10, klwf11, klwf12, klwf13,

    // WHAT WE DO - The Watoto Academy
    twa1, twa2, twa3, twa4, twa5, twa6, twa7, twa8, twa9,

    // ABOUT US - General
    team,

    // ABOUT US - Kibera
    kibera1, kibera2, kibera3, kibera4, kibera5, kibera6,

    // ABOUT US - Founder
    founder1, founder2, founder3, founder4, founder5, founder6, founder7, founder8,

    // ABOUT US - Publications
    publications1,

    // ABOUT US - Partners
    partners1,

    // Get in touch - PARTNERS
    opsahlGruppen, skullerudSkole, wwqa, youcanyole, sos,

    // DONATE
    vippsLogo, paypalLogo, donorBoxLogo, scanVipps, kidLooking, stripeLogo,

    // SPONSORSHIP
    desk, lunch, materials, uniform,

    // INNSAMLING
    classPhoto, collection, collection2
}