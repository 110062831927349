import React, { useState, useEffect } from "react";
import { collection, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage methods
import { db, storage } from "../../../firebase"; // Firebase configuration
import { Navbar, Footer, Side, Section, Loading } from "../../../components";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import "./Team.css";

const Contact = ({ isAdmin }) => {
  const [staffMembers, setStaffMembers] = useState([]);
  const [newStaff, setNewStaff] = useState({
    email: "",
    name: "",
    role: "",
    category: "",
    description_1: "",
    description_2: "",
    sortingOrder: 0,
    active: true, // Default is active
  });
  const [imageFile, setImageFile] = useState(null); // State for image file
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStaff = async () => {
      setLoading(true); // Start loading
      try {
        const querySnapshot = await getDocs(collection(db, "staff"));
        const staffData = [];
        querySnapshot.forEach((doc) => {
          staffData.push({ id: doc.id, ...doc.data() });
        });
        setStaffMembers(staffData);
      } catch (error) {
        console.error("Error fetching staff members: ", error);
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchStaff();
  }, []);

  // Function to upload the image and return its URL
  const uploadImage = async (file) => {
    const storageRef = ref(storage, `staff_images/${newStaff.email}`); // Create a reference to store the image with the staff email
    const snapshot = await uploadBytes(storageRef, file); // Upload the image to Firebase Storage
    const downloadURL = await getDownloadURL(snapshot.ref); // Get the image download URL
    return downloadURL;
  };

  // Function to get the next sorting order for a given category
  const getNextSortingOrder = (category) => {
    const membersInCategory = staffMembers.filter(
      (member) => member.category === category
    );
    const highestSortingOrder = membersInCategory.length
      ? Math.max(...membersInCategory.map((member) => member.sortingOrder))
      : 0;
    return Math.ceil(highestSortingOrder) + 1; // Round up and add 1
  };

  // Function to handle adding new staff members
  const addStaffMember = async () => {
    if (
      !newStaff.email ||
      !newStaff.name ||
      !newStaff.role ||
      !newStaff.category ||
      !newStaff.description_1
    ) {
      alert("Missing required fields. Please fill all required fields.");
      return;
    }

    try {
      let imageURL = "/default-image.png"; // Default image URL

      // Upload the image if selected
      if (imageFile) {
        imageURL = await uploadImage(imageFile); // Get the uploaded image URL
      }

      // Set the sorting order to the highest sorting order in the selected category + 1 if not provided
      const sortingOrder = newStaff.sortingOrder
        ? parseFloat(newStaff.sortingOrder)
        : getNextSortingOrder(newStaff.category);

      // Save the staff details along with the image URL and sorting order in Firestore
      await setDoc(doc(db, "staff", newStaff.email), {
        email: newStaff.email,
        name: newStaff.name,
        role: newStaff.role,
        category: newStaff.category,
        description_1: newStaff.description_1,
        description_2: newStaff.description_2,
        sortingOrder: sortingOrder, // Store the sorting order
        active: newStaff.active, // Store the active status
        img: imageURL, // Store the image URL
      });

      console.log("Staff member added successfully with email as ID.");

      // Reset form fields
      setNewStaff({
        email: "",
        name: "",
        role: "",
        category: "",
        description_1: "",
        description_2: "",
        sortingOrder: 0,
        active: true, // Reset to default active
      });
      setImageFile(null); // Clear the selected image

      // Fetch updated list of staff members
      const querySnapshot = await getDocs(collection(db, "staff"));
      const staffData = [];
      querySnapshot.forEach((doc) => {
        staffData.push({ id: doc.id, ...doc.data() });
      });
      setStaffMembers(staffData);
      closeAddPopup();
    } catch (e) {
      alert("Error adding document: ", e);
    }
  };

  const updateStaffMember = async () => {
    if (
      !newStaff.email ||
      !newStaff.name ||
      !newStaff.role ||
      !newStaff.category ||
      !newStaff.description_1
    ) {
      alert("Missing required fields. Please fill all required fields.");
      return;
    }

    try {
      let imageURL = currentMember.img || "/default-image.png"; // Default image or existing image URL

      // Upload the image if selected
      if (imageFile) {
        imageURL = await uploadImage(imageFile); // Get the uploaded image URL
      }

      // Retrieve the existing document to keep the intranet_access intact
      const staffDocRef = doc(db, "staff", newStaff.email);
      const staffDocSnap = await getDoc(staffDocRef);

      if (staffDocSnap.exists()) {
        const existingData = staffDocSnap.data();

        // Save the updated staff details, preserving intranet_access and other fields that should not change
        await setDoc(
          staffDocRef,
          {
            email: newStaff.email,
            name: newStaff.name,
            role: newStaff.role,
            category: newStaff.category,
            description_1: newStaff.description_1,
            description_2: newStaff.description_2,
            sortingOrder: parseFloat(newStaff.sortingOrder), // Store the sorting order as a number
            active: newStaff.active, // Store the active status
            img: imageURL, // Store the image URL
            intranet_access: existingData.intranet_access || [], // Preserve intranet_access if it exists
          },
          { merge: true }
        ); // Use merge to update only the changed fields

        alert("Staff member updated successfully.");

        // Fetch updated list of staff members
        const querySnapshot = await getDocs(collection(db, "staff"));
        const staffData = [];
        querySnapshot.forEach((doc) => {
          staffData.push({ id: doc.id, ...doc.data() });
        });
        setStaffMembers(staffData);

        closePopup(); // Close the popup after update
      } else {
        alert("Staff member not found.");
      }
    } catch (e) {
      alert("Error updating document: ", e);
    }
  };

  const openPopup = (member) => {
    setCurrentMember(member);
    setNewStaff({
      email: member.email,
      name: member.name,
      role: member.role,
      category: member.category,
      description_1: member.description_1,
      description_2: member.description_2,
      sortingOrder: member.sortingOrder,
      active: member.active,
    });
    setIsPopupOpen(true); // Open the popup
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentMember(null); // Clear the selected member
    setNewStaff({
      email: "",
      name: "",
      role: "",
      category: "",
      description_1: "",
      description_2: "",
      sortingOrder: 0,
      active: true, // Reset to default active
    });
  };

  const openAddPopup = () => {
    setIsAddPopupOpen(true);
  };

  const closeAddPopup = () => {
    setIsAddPopupOpen(false);
    setNewStaff({
      email: "",
      name: "",
      role: "",
      category: "",
      description_1: "",
      description_2: "",
      sortingOrder: 0,
      active: true, // Reset to default active
    });
    setImageFile(null);
  };

  const renderMember = (member) => {
    return (
      <div
        className="column"
        key={member.id}
        onClick={() => isAdmin && openPopup(member)}
      >
        <div className={`card ${!member.active && isAdmin ? "inactive" : ""}`}>
          <img
            src={member.img || "/default-image.png"}
            alt={member.name}
            style={{ width: "100%" }}
          />
          <div className="container">
            <h2>{member.name}</h2>
            <p className="title">
              {member.description_1}
              <br />
              {member.description_2 && `${member.description_2}`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Only show active members to non-admin users
  const filteredStaffMembers = isAdmin
    ? staffMembers
    : staffMembers.filter((member) => member.active);

  const boardMembers = filteredStaffMembers
    .filter((member) => member.category === "Board")
    .sort((a, b) => a.sortingOrder - b.sortingOrder);

  const advisoryMembers = filteredStaffMembers
    .filter((member) => member.category === "Advisory")
    .sort((a, b) => a.sortingOrder - b.sortingOrder);

  const teamMembers = filteredStaffMembers
    .filter((member) => member.category === "Team")
    .sort((b, a) => a.sortingOrder - b.sortingOrder);

  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        {/* <Side /> */}
        <div className="main max-1000">
          <div className="article" id="contact">
            <h2>Contact Us</h2>
            <p>Kindly get in touch with us per email.</p>
            <br />
            <p className="email">
              Operational enquiries:{" "}
              <u>
                <a href="mailto:brandon@thewatotoprogram.org">
                  brandon@thewatotoprogram.org
                </a>
              </u>
              <br />
              Donation & partner enquiries:{" "}
              <u>
                <a href="mailto:magnus@thewatotoprogram.org">
                  magnus@thewatotoprogram.org
                </a>
              </u>
            </p>
          </div>
          <br />
          {loading ? (
            <>
              <div className="article" id="team">
                <h2>Team</h2>
                <Loading />
              </div>
            </>
          ) : (
            <>
              <br />

              <div className="article" id="board">
                <h2>Board</h2>
                <div className="flex-container">
                  <div className="row2">
                    {boardMembers.map((member) => renderMember(member))}
                  </div>
                </div>
              </div>

              <br />

              <div className="article" id="advisory">
                <h2>Advisory Board</h2>
                <div className="flex-container advisory-board-container">
                  <div className="row2">
                    {advisoryMembers.map((member) => renderMember(member))}
                  </div>
                </div>
              </div>

              <br />

              <div className="article" id="team">
                <h2>Team</h2>
                <div className="flex-container">
                  <div className="row2">
                    {teamMembers.map((member) => renderMember(member))}
                  </div>
                </div>
              </div>

              <br />
            </>
          )}

          {isPopupOpen && currentMember && (
            <div className="staff-popup-container" id="staff-popup-container">
              <h3>Edit Staff Member</h3>
              <table className="staff-popup-table">
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>
                      <input
                        type="text"
                        className="staff-popup-input"
                        placeholder="Email"
                        value={newStaff.email}
                        onChange={(e) =>
                          setNewStaff({ ...newStaff, email: e.target.value })
                        }
                        disabled // Email is the document ID and can't be changed
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>
                      <input
                        type="text"
                        className="staff-popup-input"
                        placeholder="Name"
                        value={newStaff.name}
                        onChange={(e) =>
                          setNewStaff({ ...newStaff, name: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>
                      <select
                        className="staff-popup-select"
                        value={newStaff.category}
                        onChange={(e) =>
                          setNewStaff({
                            ...newStaff,
                            category: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Category</option>
                        <option value="Board">Board</option>
                        <option value="Advisory">Advisory</option>
                        <option value="Team">Team</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>
                      <select
                        className="staff-popup-select"
                        value={newStaff.role}
                        onChange={(e) =>
                          setNewStaff({ ...newStaff, role: e.target.value })
                        }
                      >
                        <option value="">Select Role</option>
                        <option value="Admin">Admin</option>
                        <option value="Staff">Staff</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Description 1</td>
                    <td>
                      <input
                        type="text"
                        className="staff-popup-input"
                        placeholder="Description 1"
                        value={newStaff.description_1}
                        onChange={(e) =>
                          setNewStaff({
                            ...newStaff,
                            description_1: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Description 2 (optional)</td>
                    <td>
                      <input
                        type="text"
                        className="staff-popup-input"
                        placeholder="Description 2"
                        value={newStaff.description_2}
                        onChange={(e) =>
                          setNewStaff({
                            ...newStaff,
                            description_2: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Image</td>
                    <td>
                      <input
                        type="file"
                        className="staff-popup-input"
                        onChange={(e) => setImageFile(e.target.files[0])}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Sorting Order</td>
                    <td>
                      <input
                        type="number"
                        step="0.1"
                        className="staff-popup-input"
                        placeholder="Sorting Order"
                        value={newStaff.sortingOrder}
                        onChange={(e) =>
                          setNewStaff({
                            ...newStaff,
                            sortingOrder: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Active</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={newStaff.active}
                        onChange={(e) =>
                          setNewStaff({
                            ...newStaff,
                            active: e.target.checked,
                          })
                        }
                      />
                      <label>Active (Visible to non-admin users)</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        className="staff-popup-button staff-popup-close-button"
                        onClick={closePopup}
                      >
                        Close
                      </button>
                    </td>
                    <td>
                      <button
                        className="staff-popup-button"
                        onClick={updateStaffMember}
                      >
                        Update Staff Member
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          <br />

          {isAdmin && (
            <div>
              <button className="staff-popup-button" onClick={openAddPopup}>
                Add New Staff Member
              </button>

              {isAddPopupOpen && (
                <div className="staff-popup-container">
                  <div className="staff-popup">
                    <h3>Add New Staff Member</h3>
                    <table className="staff-popup-table">
                      <tbody>
                        <tr>
                          <td>Email</td>
                          <td>
                            <input
                              type="text"
                              className="staff-popup-input"
                              placeholder="Email"
                              value={newStaff.email}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  email: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Name</td>
                          <td>
                            <input
                              type="text"
                              className="staff-popup-input"
                              placeholder="Name"
                              value={newStaff.name}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  name: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Category</td>
                          <td>
                            <select
                              className="staff-popup-select"
                              value={newStaff.category}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  category: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Category</option>
                              <option value="Board">Board</option>
                              <option value="Advisory">Advisory</option>
                              <option value="Team">Team</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Role</td>
                          <td>
                            <select
                              className="staff-popup-select"
                              value={newStaff.role}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  role: e.target.value,
                                })
                              }
                            >
                              <option value="">Select Role</option>
                              <option value="Admin">Admin</option>
                              <option value="Staff">Staff</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Description 1</td>
                          <td>
                            <input
                              type="text"
                              className="staff-popup-input"
                              placeholder="Description 1"
                              value={newStaff.description_1}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  description_1: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Description 2 (optional)</td>
                          <td>
                            <input
                              type="text"
                              className="staff-popup-input"
                              placeholder="Description 2"
                              value={newStaff.description_2}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  description_2: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Image</td>
                          <td>
                            <input
                              type="file"
                              className="staff-popup-input"
                              onChange={(e) => {
                                setImageFile(e.target.files[0]);
                              }}
                            />
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Sorting Order</td>
                          <td>
                            <input
                              type="number"
                              step="0.1"
                              className="staff-popup-input"
                              placeholder="Sorting Order (optional)"
                              value={newStaff.sortingOrder}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  sortingOrder: e.target.value,
                                })
                              }
                            />
                          </td>
                        </tr> */}
                        <tr>
                          <td>Active</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={newStaff.active}
                              onChange={(e) =>
                                setNewStaff({
                                  ...newStaff,
                                  active: e.target.checked,
                                })
                              }
                            />
                            <label>Active (visible to non-admin users)</label>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <button
                              className="staff-popup-button"
                              onClick={addStaffMember}
                            >
                              Add Staff Member
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <button
                              className="staff-popup-button staff-popup-close-button"
                              onClick={closeAddPopup}
                            >
                              Close
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
