import React, { useEffect, useState, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebase';
import { doc, getDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const [simulatedUser, setSimulatedUser] = useState(null); 
  const location = useLocation();
  const [loadingUser, setLoadingUser] = useState(true);

  // Function to get user by phone number from 'users' collection
  async function getUserByPhoneNumber(userNumber) {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('phoneNumber', '==', userNumber));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return userDoc; // Return the full user doc if found
    } else {
      console.log('No user found with this phone number');
      return null;
    }
  }

  // Function to get user by email from 'staff' collection
  async function getUserByEmail(userEmail) {
    const staffRef = doc(db, 'staff', userEmail); // Look for document where the document ID is the email
    const docSnapshot = await getDoc(staffRef);

    if (docSnapshot.exists()) {
      return docSnapshot; // Return the user doc if found
    } else {
      console.log('No user found with this email');
      return null;
    }
  }

  const simulateUserAccess = async (email) => {
    const userDoc = await getUserByEmail(email);
    if (userDoc) {
      setSimulatedUser(userDoc.data());
    }
  };
  // Revert to admin's original session
  const stopSimulation = () => {
    setSimulatedUser(null);
    console.log("Simulation stopped, returning to admin session.");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userEmail = user.email;
        const userNumber = user.phoneNumber;
        let userRef;

        // Check if the user is authenticated via email or phone number
        if (userEmail) {
          // Fetch from 'staff' collection if the user has an email
          userRef = await getUserByEmail(userEmail);
        } else if (userNumber) {
          // Fetch from 'users' collection if the user has a phone number
          userRef = await getUserByPhoneNumber(userNumber);
        }

        if (userRef) {
          const userData = userRef.data();
          setUserData(userData);
          console.log('User Data:', userData);
        } else {
          console.error('User document not found');
        }
        setLoadingUser(false);
      }
    };

    fetchUserData();
  }, [user]);

  const activeUser = simulatedUser || userData; 

  // Check if user has admin or sponsor rights based on the role in the user data
  const isAdmin = userData && userData.role === 'Admin' && location.pathname.includes("/admin");
  const isSponsor = userData && userData.role === 'Sponsor'; // Does not require /sponsor in the URL
  const isStaff = userData && userData.role === 'Staff'; 

  useEffect(() => {
    console.log("isAdmin in AuthProvider:", isAdmin);
    console.log("isSponsor in AuthProvider:", isSponsor);
    console.log("isSponsor in AuthProvider:", isStaff);
    console.log("User Data in AuthProvider:", userData);
  }, [isAdmin, isSponsor, userData]);

  return (
    <AuthContext.Provider value={{   user,
      activeUser,
      isAdmin,
      isSponsor,
      isStaff,
      userData: activeUser,
      setUserData,
      simulateUserAccess,
      stopSimulation,
      loadingUser, }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to access authentication context
const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
