const LoadingIndicator = () => (
  <div style={{ textAlign: "center" }}>
    <div className="img">
      <div className="loading-container">
        <div className="loading-animation"></div>
        <div className="loading-text">Loading...</div>
      </div>
    </div>
  </div>
);

export default LoadingIndicator;
