import React from "react";
import { images } from "../../constants";
import { GoBack } from "../../components";

const Admission = ({ setActiveSection }) => {
  return (
    <div className="admissions subjects" id="page-container">
      <div className="bodyMargin left">
        <GoBack setActiveSection={setActiveSection} />
        <h2>Admission</h2>

        <p>
          We admit the most motivated and talented students from Kibera who lack
          the necessary means to achieve their greatest potential in school.
        </p>
        <br />
        <div className="blueBackground flex-image-text">
          <div>
            <h3>Selection Criteria</h3>
            <p>
              Students selected to join The Watoto Academy are evaluated with
              the following criteria:
            </p>
            <br />
            <p>- Motivation</p>
            <p>- Responsible citizenship</p>
            <p>- Academic excellence</p>
            <p>- Financial need</p>
            <p>- Leadership and entrepreneurship potential</p>
            <p>- Critical thinking</p>
            <p>- Problem solving skills</p>
          </div>
          <img src={images.classroom} className="img" alt="" />
        </div>

        <div className="blueBackground">
          <h3>Application</h3>
          <p>Applications are closed for the 2025 school year.</p>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Admission;
