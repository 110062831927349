import React, { createContext, useState, useEffect } from "react";
import { useAuth } from "../../../../AuthProvider";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

export const PageCountsContext = createContext();

export const PageCountsProvider = ({ children }) => {
  const { isAdmin, userData } = useAuth();
  const [pageCounts, setPageCounts] = useState({});

  useEffect(() => {
    const fetchAllCounts = async () => {
      if (!userData || !userData.email) {
        return;
      }
  
      try {
        let economicsQuery, reportsQuery;
        const currentYear = new Date().getFullYear();
        const reportsPath = `reports/${currentYear}/reports`;
  
        if (isAdmin) {
          economicsQuery = collection(db, "economics");
          reportsQuery = collection(db, reportsPath);
        } else {
          economicsQuery = query(
            collection(db, "economics"),
            where("userId", "==", userData.email)
          );
          reportsQuery = query(
            collection(db, reportsPath),
            where("userId", "==", userData.email)
          );
        }
  
        const [economicsSnapshot, reportsSnapshot] = await Promise.all([
          getDocs(economicsQuery),
          getDocs(reportsQuery),
        ]);
  
        const economicsData = economicsSnapshot.docs.map((doc) => doc.data());
        const reportsData = reportsSnapshot.docs.map((doc) => doc.data());
  
        const newPageCounts = {};
  
        // Economics
        if (isAdmin) {
          const economicsCount = economicsData.filter((request) =>
            ["Awaiting approval", "Pending review", "Receipt rejected"].includes(
              request.status
            )
          ).length;
          newPageCounts["economics"] = economicsCount;
        } else {
          const economicsCount = economicsData.filter((request) =>
            ["Pending review", "Receipt rejected"].includes(request.status) ||
            (["Approved", "Partially approved"].includes(request.status) &&
              !request.receiptUrl)
          ).length;
          newPageCounts["economics"] = economicsCount;
        }
  
        // Reports
        const currentWeek = getWeekNumber(new Date());
        const draftForCurrentWeek = reportsData.some(
          (report) =>
            report.week === `Week ${currentWeek}` && report.status === "draft"
        );
        const submittedForCurrentWeek = reportsData.some(
          (report) =>
            report.week === `Week ${currentWeek}` && report.status === "submitted"
        );
  
        if (isAdmin) {
          const toReview = reportsData.filter(
            (report) =>
              report.status === "submitted" || report.status === "feedbackDraft"
          ).length;
          newPageCounts["reports"] = toReview;
        } else {
          const toCreate =
            canCreateReport() && !draftForCurrentWeek && !submittedForCurrentWeek
              ? 1
              : 0;
  
          const toConfirm = reportsData.filter(
            (report) => report.status === "awaitingRead"
          ).length;
  
          const draftsCount = draftForCurrentWeek ? 1 : 0;
  
          newPageCounts["reports"] = toCreate + toConfirm + draftsCount;
        }
  
        setPageCounts(newPageCounts);
      } catch (error) {
        console.error("Error fetching page counts:", error);
      }
    };
  
    fetchAllCounts();
  }, [isAdmin, userData]);
  

  const canCreateReport = () => {
    const today = new Date().getDay();
    return today >= 4 || today === 0; 
  };

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const startDayOfWeek = startDate.getDay();
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1;
    return Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
  };

  return (
    <PageCountsContext.Provider value={pageCounts}>
      {children}
    </PageCountsContext.Provider>
  );
};
