import React from "react";
import "./GoBack.css";

const GoBack = ({ setActiveSection }) => {
  return (
    <div className="goback" onClick={() => setActiveSection("home")}>
      <u className="goback-button">
        <i className="fa-solid fa-arrow-left"></i> Back
      </u>
    </div>
  );
};

export default GoBack;
