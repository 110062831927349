import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  Footer,
  Side,
  Slideshow,
  Section,
  NextItems,
  MyLink,
} from "../../../components";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useParams } from "react-router-dom";
import "./Articles.css";
import { useArticles } from "../Articles/ArticlesContext";
import { useAuthState } from "react-firebase-hooks/auth"; // If using react-firebase-hooks
import { auth } from "../../../firebase";

const Article = ({ isAdmin, updatedFields }) => {
  const [user] = useAuthState(auth);
  const [article, setArticle] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { articles, articlesLoading, deleteArticle } = useArticles();
  const { articleId } = useParams();
  // const dots = article.slides ? Array(article.slides.length).fill(null) : [];
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("updatedFields:", updatedFields);
  // }, [updatedFields]);

  useEffect(() => {
    if (!articlesLoading) {
      const currentArticle = articles.find(
        (article) => article.id === Number(articleId)
      );
      setArticle(currentArticle);
    }
  }, [articles, articleId, articlesLoading]);

  const toggleArticleStatus = async () => {
    if (!article || !articleId) return; // Guard clause

    if (article.status === "Draft") {
      const hasMissingImages = article.sections.some(
        (section) => !section.section_image
      );

      if (hasMissingImages) {
        alert("Cannot publish the article. All sections must have an image.");
        return;
      }
    }

    const newStatus = article.status === "Published" ? "Draft" : "Published"; // Toggle status
    const articleRef = doc(db, "articles", articleId.toString()); // Reference to Firestore document

    try {
      setIsProcessing(true);
      setIsUpdating(true);
      await updateDoc(articleRef, {
        status: newStatus,
      });

      console.log(`Article status updated to ${newStatus}`);
      setTimeout(() => {
        setArticle({ ...article, status: newStatus });
        setIsProcessing(false);
        setIsUpdating(false);
      }, 2000);
    } catch (error) {
      console.error("Error updating article status: ", error);
    }
  };

  const handleDeleteArticle = async () => {
    if (!articleId) return;

    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this article? This action cannot be reversed."
    );
    if (!confirmDeletion) return;
    setIsProcessing(true);
    setIsDeleting(true);

    await deleteArticle(articleId);

    setTimeout(() => {
      navigate("/admin/articles");
      setIsProcessing(false);
      setIsDeleting(false);
    }, 2000);
  };

  const handleUpdateArticle = async (updatedFields) => {
    console.log("Updating article with id:", articleId);
    if (!articleId || !article || !updatedFields) {
      console.log(
        "Missing required data. Cannot update article.",
        articleId,
        article,
        updatedFields
      );
      return;
    }

    const articleRef = doc(db, "articles", articleId.toString());
    try {
      const updates = {
        title: updatedFields.header || article.header,
        description: updatedFields.description || article.description,
        day: updatedFields.day || article.day,
        month: updatedFields.month || article.month,
        year: updatedFields.year || article.year,
        // Add other fields as necessary
      };
      console.log("updates:", updates);

      await updateDoc(articleRef, updates);
      console.log("Article updated successfully");
      setIsUpdated(false); // Reset the update flag after the update is successful
    } catch (error) {
      console.error("Error updating article: ", error);
    }
  };

  const handleUpdateSection = async (updatedSectionFields) => {
    console.log(
      "Updating section with sectionId:",
      updatedSectionFields.sectionId
    );
    if (!articleId || !article || !updatedSectionFields) {
      console.log(
        "Missing required data. Cannot update section.",
        articleId,
        article,
        updatedSectionFields
      );
      return;
    }

    const articleRef = doc(db, "articles", articleId.toString());
    try {
      const docSnapshot = await getDoc(articleRef);
      if (docSnapshot.exists()) {
        const sections = docSnapshot.data().sections || [];
        const updatedSections = sections.map((section) => {
          if (section.section_id === updatedSectionFields.sectionId) {
            return {
              ...section,
              section_title:
                updatedSectionFields.sectionTitle !== undefined
                  ? updatedSectionFields.sectionTitle
                  : section.section_title,
              section_description:
                updatedSectionFields.sectionDescription !== undefined
                  ? updatedSectionFields.sectionDescription
                  : section.section_description,
            };
          }
          return section;
        });

        await updateDoc(articleRef, { sections: updatedSections });
        console.log("Section updated successfully");
      } else {
        console.log("Article not found");
      }
    } catch (error) {
      console.error("Error updating section: ", error);
    }
  };

  const addNewSection = async () => {
    if (!article) return;
    const sections = article.sections || [];

    const newSectionId =
      sections.length > 0
        ? sections.reduce(
            (maxId, section) => Math.max(maxId, section.section_id),
            0
          ) + 1
        : 1;

    const newSection = {
      section_id: newSectionId,
      section_title: "",
      section_description: "New Section Description",
      section_image: "",
    };

    // const updatedSections = [...article.sections, newSection];
    const updatedSections = [...sections, newSection];
    setArticle({ ...article, sections: updatedSections });

    // Firestore update
    const articleRef = doc(db, "articles", articleId);
    try {
      await updateDoc(articleRef, {
        sections: updatedSections,
      });
      console.log("Article updated with new section in Firestore");
    } catch (error) {
      console.error("Error adding new section to Firestore: ", error);
    }
  };

  const deleteSection = async (sectionId) => {
    if (!article || !article.sections) return;

    const updatedSections = article.sections
      .filter((section) => section.section_id !== sectionId)
      .map((section, index) => ({ ...section, section_id: index + 1 })); // Reassign section_ids to maintain order

    setArticle({ ...article, sections: updatedSections });

    // Firestore update
    const articleRef = doc(db, "articles", articleId);
    try {
      await updateDoc(articleRef, {
        sections: updatedSections,
      });
      console.log("Article updated after section deletion in Firestore");
    } catch (error) {
      console.error("Error deleting section in Firestore: ", error);
    }
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <Side isProcessing={isProcessing} />

        <div className="main">
          {articlesLoading ? (
            <div className="loading-container">
              <div className="loading-animation"></div>
              <div className="loading-text">Loading...</div>
            </div>
          ) : !article ? (
            <div>
              <p>No article found.</p>
              <br />
              <MyLink to="/articles">
                <u>Go to Newsletters</u>
              </MyLink>
            </div>
          ) : !user && article && article.status === "Draft" ? (
            <div>
              <p>No permission.</p>
              <br />
              <MyLink to="/articles">
                <u>Go to Newsletters</u>
              </MyLink>
            </div>
          ) : (
            <>
              <div>
                <MyLink to={`/articles#${articleId}`}>
                  <div className="div-back">
                    <p className="allNewsLetters">
                      <i className="fa-solid fa-arrow-left"></i>Back to
                      Newsletters
                    </p>
                  </div>
                </MyLink>

                {isAdmin && (
                  <>
                    {isUpdating ? (
                      ""
                    ) : (
                      <span
                        className={`status-${article.status.toLowerCase()}`}
                      >
                        {article.status === "Published" ? (
                          <>
                            {article.status}{" "}
                            <i className="fa fa-check-circle"></i>
                          </>
                        ) : (
                          <>
                            {article.status}{" "}
                            <i className="fa fa-pencil-alt"></i>
                          </>
                        )}
                      </span>
                    )}

                    <span className="flex-image-text-2">
                      <span
                        onClick={toggleArticleStatus}
                        className="div-readmore status"
                      >
                        {article.status === "Published" ? (
                          <>
                            <i class="fa-solid fa-download"></i> Unpublish{" "}
                            {isUpdating ? (
                              <span className="rolling-loading-animation blue small"></span>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            <i class="fa-solid fa-upload"></i> Publish{" "}
                            {isUpdating ? (
                              <span className="rolling-loading-animation small blue"></span>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </span>
                      {isUpdating ? (
                        ""
                      ) : (
                        <span
                          className="div-readmore status"
                          onClick={handleDeleteArticle}
                        >
                          <i class="fa-solid fa-trash-can"></i> Delete{" "}
                          {isDeleting ? (
                            <span className="rolling-loading-animation blue small"></span>
                          ) : (
                            ""
                          )}
                        </span>
                      )}
                    </span>
                  </>
                )}
              </div>

              <div className="article" id={articleId}>
                {isAdmin && (
                  <p className="small-info">
                    <i class="fa-solid fa-user-pen"></i> You are currently in
                    Edit mode. Click the green "ADMIN" toggle in the navigation
                    bar to see how the article appears to users.
                  </p>
                )}
                <Section
                  articleId={articleId}
                  header={article.title} // needs to be title, db has title
                  day={article.day}
                  month={article.month}
                  year={article.year}
                  description={article.description}
                  img={article.img}
                  isAdmin={isAdmin}
                  isArticle={true}
                  onUpdateArticle={handleUpdateArticle}
                />

                {article?.sections?.map((section) => (
                  <div key={section.section_id}>
                    <Section
                      articleId={articleId}
                      sectionId={section.section_id}
                      sectionTitle={section.section_title}
                      titlespan={section.section_titlespan}
                      img={section.section_image}
                      sectionDescription={section.section_description}
                      isAdmin={isAdmin}
                      onUpdateSection={handleUpdateSection}
                      isArticle={true}
                      onDeleteSection={() => deleteSection(section.section_id)}
                    />
                  </div>
                ))}

                {/* {article.slides && article.slides.length > 0 && (
            <Slideshow slides={article.slides} dots={dots} />
          )} */}
              </div>
              <br />
              {isAdmin && (
                <div className="article">
                  <span
                    onClick={addNewSection}
                    className="div-readmore addSection"
                  >
                    Add New Section <i class="fa-solid fa-plus"></i>
                  </span>
                </div>
              )}
              {/* <NextItems
                items={articles}
                currentIdentifier={articleId}
                identifierType="id"
              /> */}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Article;
