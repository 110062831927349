import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebase"; // Firebase configuration
import { Navbar, Footer } from "../../../components";
import Locationscroll from "../../../components/Locationscroll/Locationscroll";
import "./Fundraiser.css";

const Fundraiser = ({ isAdmin }) => {
  useEffect(() => {
    // Dynamically load the Donorbox widget script
    const script = document.createElement("script");
    script.src = "https://donorbox.org/widget.js";
    script.setAttribute("paypalExpress", "true");
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <Locationscroll />
      <Navbar isAdmin={isAdmin} />
      <div id="flex">
        <iframe
          className="fundraiser-iframe"
          src="https://donorbox.org/support-the-watoto-library?default_interval=o&amount=30"
        />
      </div>
      <Footer />
    </div>
  );
};

export default Fundraiser;
