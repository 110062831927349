import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, getDocs, addDoc, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const ProjectsContext = createContext();

export const useProjects = () => useContext(ProjectsContext);

export const ProjectsProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setProjectsLoading(true);
    const projectsCol = collection(db, 'whatWeDo');
    const projectsSnapshot = await getDocs(projectsCol);
    const projectsList = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    sortAndSetProjects(projectsList);
    setProjectsLoading(false);
  };

  const sortAndSetProjects = (projectsList) => {
    const sortedProjects = sortProjects(projectsList);
    setProjects(sortedProjects);
  };

  const sortProjects = (projectsList) => {
    // Implement sorting logic here if needed
    return projectsList;
  };

  const addProject = async (projectData) => {
    try {
      await setDoc(doc(db, 'whatWeDo', projectData.url), projectData); // Use 'whatWeDo' collection
      await fetchProjects(); // Refresh the projects list after adding a new one
    } catch (error) {
      console.error("Error adding new project:", error);
    }
  };
  

  const deleteProject = async (projectId) => {
    try {
      await deleteDoc(doc(db, 'whatWeDo', projectId)); // Use 'whatWeDo' collection
      await fetchProjects(); // Refresh the projects list after deletion
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };
  

  return (
    <ProjectsContext.Provider value={{ projects, setProjects, projectsLoading, addProject, deleteProject }}>
      {children}
    </ProjectsContext.Provider>
  );
};