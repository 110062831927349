import React, { useState } from "react";
import { images } from "../constants";
import { Navbar, Partners, Footer, MyLink } from "../../../../components";
import InfoBar from "../components/InfoBar/InfoBar";
import Banner from "../components/Banner/Banner";
import Feature from "../components/Feature/Feature";
import SplitView from "../components/SplitView/SplitView";
import SchoolNavbar from "../components/Navbar/SchoolNavbar";
import AboutSchool from "./About/AboutSchool";
import Concept from "./Concept/Concept";
import Admission from "./Admission/Admission";
import Timetable from "./Timetable/Timetable";
import Community from "./Community/Community";
import "./index.css";

const HomePage = () => {
  const [activeSection, setActiveSection] = useState("home");

  const renderSection = () => {
    switch (activeSection) {
      case "concept":
        return <Concept setActiveSection={setActiveSection} />;
      case "timetable":
        return <Timetable setActiveSection={setActiveSection} />;
      case "community":
        return <Community setActiveSection={setActiveSection} />;
      case "about":
        return <AboutSchool setActiveSection={setActiveSection} />;
      case "admission":
        return <Admission setActiveSection={setActiveSection} />;
      default:
        return (
          <div className="school-home">
            <Banner />
            <h2 className="statement inline-group">
              <span>We redefine</span> <span>traditional education.</span>
            </h2>
            <div className="features-section">
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.redefining}
                title="Educational opportunities"
                description="The Watoto Academy is formed under the principle of creating education opportunities and chances for children coming from very vulnerable families with a dream of achieving success. We are using education to change their lives, and that of their families."
              />{" "}
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.community}
                title="Fully sponsored students"
                description="We are offering a fully sponsored school to some of the most brilliant children in the slums of Kibera, as we guide and provide them with mentorship programs that will enable them to be responsible citizens and leaders of today and tomorrow."
              />{" "}
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.meaningful}
                title="Values of future leaders"
                description="We are nourishing them with values of responsibility, integrity, and honesty, as we put our focus on the growth of the child as a whole person through our hollistic curriculum and school activities that are targeting  to improve their physical, mental, emotional and spiritual growth.
          "
              />{" "}
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.together}
                title="Giving back to community"
                description="We strongly believe in the philosophy of Ubuntu,'I am because we are'. In return, our students are required to give back to the community by participating in our river clean up activity that takes place every Saturday morning."
              />{" "}
            </div>
            <div className="bodyMargin paragraph">
              <p>
                Only 15% of Kibera's amazing children are able to attend high
                school.{" "}
              </p>
              <p className="admitted">
                <b>
                  The Watoto Academy has in{" "}
                  <u>
                    <span className="twl-color">2024</span>
                  </u>{" "}
                  admitted{" "}
                  <u>
                    <span className="twl-color">20 children</span>
                  </u>{" "}
                  aged 14 years to join Grade 7,
                </b>
              </p>
              <p className="admitted">
                <b>
                  and in{" "}
                  <u>
                    <span className="twl-color">2025</span>
                  </u>{" "}
                  admitted another{" "}
                  <u>
                    <span className="twl-color">12 children</span>
                  </u>{" "}
                  aged 14 years to join Grade 7,
                </b>
              </p>
              <p>where they will receive modern, high-quality education.</p>
              <br />
              <div className="initiative-container">
                <p className="initiative">
                  Our initiative is aimed at children from families who do not
                  have the means to provide better education for their children,
                  but who are eager and ready to receive quality education for
                  themselves and, in return, use that education to serve their
                  community.
                </p>
                <img
                  src={images.TWLlogo}
                  alt="Initiative Logo"
                  className="initiative-logo"
                />
              </div>
            </div>

            <h2>How do we do it?</h2>

            <div className="features-section how">
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.fees}
                title="Conducive environment"
                description="We have a cap of 20 students per class and use 40% of our lessons as group-work."
              />{" "}
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.materials}
                title="Fully sponsored students"
                description="Our students do not pay any school fees and get uniform, books and materials for free."
              />{" "}
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.food}
                title="Two meals a day"
                description="Many students go to school without food. We therefore provide two nutritious meals every day at no cost."
              />{" "}
              <Feature
                iconClassName="fa-regular fa-star"
                image={images.curriculum}
                title="Hollistic curriculum"
                description="The students are taught real-life skills that empower them to create a better future."
              />
            </div>

            <SplitView />
          </div>
        );
    }
  };

  return (
    <div className="school-parent">
      {/* <InfoBar /> */}
      <Navbar />
      <SchoolNavbar setActiveSection={setActiveSection} />
      {renderSection()}

      <Partners />
      <Footer />
    </div>
  );
};

export default HomePage;
