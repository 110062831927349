import React, { useState, useEffect, useRef } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { Loading } from "../../../../components";
import { useAuth } from "../../../../AuthProvider";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { Timestamp } from "firebase/firestore";
import "./StudentAttendance.css"; // Add your custom CSS

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const attendanceOptions = ["Present", "Not Present"];

const StudentAttendance = () => {
  const { isAdmin, userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [studentClasses, setStudentClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [students, setStudents] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [subjects, setSubjects] = useState([]);
  const [lessons, setLessons] = useState({}); // Store lessons by day
  const [showPopup, setShowPopup] = useState(false);
  const [newLesson, setNewLesson] = useState({
    subjectId: "",
    day: "",
    startTime: "",
    endTime: "",
    report: "",
  });

  const [filterClass, setFilterClass] = useState("");
  const [showDropdowns, setShowDropdowns] = useState({});
  const [confirmedDays, setConfirmedDays] = useState({});
  const [editingLessonId, setEditingLessonId] = useState(null); // Track which lesson is being edited

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userData) {
          setLoading(true);
          return;
        }

        const db = getFirestore();

        // Fetch classes
        const classesCollection = collection(db, "classes");
        const classSnapshot = await getDocs(classesCollection);
        const currentYear = new Date().getFullYear(); // Get current year

        const classesData = classSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((cls) => cls.year === currentYear); // Filter by current year

        setStudentClasses(classesData);

        // Fetch subjects
        const subjectsCollection = collection(db, "subjects");
        const subjectsSnapshot = await getDocs(subjectsCollection);
        const subjectsData = subjectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          abbreviation: doc.data().abbreviation,
          teacherId: doc.data().teacherId,
        }));
        setSubjects(subjectsData);

        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    const savedClass = localStorage.getItem("selectedClass");
    if (savedClass) {
      setSelectedClass(savedClass);
    }
  }, []);

  // New useEffect to call fetchStudentsForClass after both studentClasses and selectedClass are available
  useEffect(() => {
    if (selectedClass && studentClasses.length > 0) {
      fetchStudentsForClass(selectedClass);
    }
  }, [selectedClass, studentClasses]); // Watch both selectedClass and studentClasses

  const dropdownRef = useRef({}); // Object to hold refs for each dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRef.current).forEach((key) => {
        if (
          dropdownRef.current[key] &&
          !dropdownRef.current[key].contains(event.target)
        ) {
          setShowDropdowns((prev) => ({
            ...prev,
            [key]: false,
          }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function toKenyanDate(date) {
    // Convert the input date to a localized string in Africa/Nairobi
    const localString = date.toLocaleString("en-US", {
      timeZone: "Africa/Nairobi",
    });
    // Re-parse that string into a new Date object
    return new Date(localString);
  }

  function getStartOfKenyanWeek(baseDate) {
    // 1) Convert the incoming date to Nairobi time
    const kenyanDate = toKenyanDate(baseDate);

    // 2) Figure out how many days we need to shift to get to Monday
    const day = kenyanDate.getDay(); // 0=Sunday, 1=Monday, etc.
    const offset = day === 0 ? -6 : 1 - day; // If Sunday => -6, else => (1 - day)

    // 3) Move kenyanDate by that offset, then set it to 00:00
    kenyanDate.setDate(kenyanDate.getDate() + offset);
    kenyanDate.setHours(0, 0, 0, 0);
    return kenyanDate;
  }

  function toKenyanDateKey(date) {
    const kenyan = toKenyanDate(date);
    const yyyy = kenyan.getFullYear();
    const mm = String(kenyan.getMonth() + 1).padStart(2, "0");
    const dd = String(kenyan.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`; // e.g. "2025-01-09"
  }

  const fetchStudentsForClass = async (classId) => {
    try {
      const db = getFirestore();

      const selectedClassDoc = studentClasses.find((cls) => cls.id === classId);

      if (!selectedClassDoc) {
        console.error("Class not found in the fetched classes list.");
        return;
      }

      const classDocRef = doc(db, "classes", selectedClassDoc.id);
      const classSnapshot = await getDoc(classDocRef);

      if (!classSnapshot.exists()) {
        console.error("Selected class data not found.");
        return;
      }

      const classData = classSnapshot.data();

      // ✅ Correct field is `students`
      setStudents(classData.students || []);

      // ✅ Properly format lessons
      const lessonsData = classData.lessons || {};
      const formattedLessons = {};

      Object.keys(lessonsData).forEach((date) => {
        formattedLessons[date] = lessonsData[date].map((lesson) => ({
          ...lesson,
          attendance: lesson.attendance || {},
        }));
      });

      setLessons(formattedLessons);
      setConfirmedDays(classData.confirmedDays || {});

      // ✅ Map attendance correctly
      const attendanceState = {};
      (classData.students || []).forEach((student) => {
        attendanceState[student.id] = {};
        Object.keys(lessonsData || {}).forEach((day) => {
          lessonsData[day].forEach((lesson) => {
            attendanceState[student.id][day] = {
              ...attendanceState[student.id][day],
              [lesson.lessonId]: lesson.attendance?.[student.id] || "",
            };
          });
        });
      });
      setAttendance(attendanceState);
    } catch (error) {
      console.error("Error fetching class data:", error);
    }
  };

  const handleLessonTimeChange = (lessonId, key, value) => {
    setLessons((prevLessons) => {
      const updatedLessons = { ...prevLessons };
      Object.keys(updatedLessons).forEach((day) => {
        updatedLessons[day] = updatedLessons[day].map((lesson) =>
          lesson.lessonId === lessonId ? { ...lesson, [key]: value } : lesson
        );
      });
      return updatedLessons;
    });
  };

  const handleLessonSubjectChange = (lessonId, subjectId) => {
    setLessons((prevLessons) => {
      const updatedLessons = { ...prevLessons };
      Object.keys(updatedLessons).forEach((day) => {
        updatedLessons[day] = updatedLessons[day].map((lesson) =>
          lesson.lessonId === lessonId ? { ...lesson, subjectId } : lesson
        );
      });
      return updatedLessons;
    });
  };

  const saveUpdatedLessons = async () => {
    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", selectedClass); // Correct collection

      await updateDoc(classDocRef, {
        lessons,
      });

      console.log("Lessons successfully updated!");
    } catch (error) {
      console.error("Error updating lessons:", error);
    }
  };

  const handleAttendanceChange = async (studentId, day, lessonId, status) => {
    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", selectedClass);

      // Update local state first for immediate UI feedback
      setLessons((prevLessons) => {
        const updatedLessons = { ...prevLessons };
        updatedLessons[day] = updatedLessons[day].map((lesson) => {
          if (lesson.lessonId === lessonId) {
            return {
              ...lesson,
              attendance: {
                ...lesson.attendance,
                [studentId]: status,
              },
            };
          }
          return lesson;
        });
        return updatedLessons;
      });

      setAttendance((prevAttendance) => {
        const updatedAttendance = { ...prevAttendance };
        if (!updatedAttendance[studentId]) {
          updatedAttendance[studentId] = {};
        }
        if (!updatedAttendance[studentId][day]) {
          updatedAttendance[studentId][day] = {};
        }
        updatedAttendance[studentId][day][lessonId] = status;
        return updatedAttendance;
      });

      // Persist the update to Firestore
      await updateDoc(classDocRef, {
        [`lessons.${day}`]: lessons[day].map((lesson) =>
          lesson.lessonId === lessonId
            ? {
                ...lesson,
                attendance: {
                  ...lesson.attendance,
                  [studentId]: status,
                },
              }
            : lesson
        ),
      });

      console.log("Attendance successfully updated!");
      setShowDropdowns((prev) => ({
        ...prev,
        [`${studentId}-${lessonId}`]: false,
      }));
    } catch (error) {
      console.error("Error updating attendance:", error);
      alert("Failed to update attendance. Please try again.");
    }
  };

  const handleToggleDropdown = (studentId, lessonId, day) => {
    // Only allow toggling the dropdown if the day is not confirmed
    if (!confirmedDays[day]) {
      setShowDropdowns((prev) => ({
        ...prev,
        [`${studentId}-${lessonId}`]: !prev[`${studentId}-${lessonId}`],
      }));
    }
  };

  const getIcon = (status, isConfirmed) => {
    let icon = "";
    let className = "icon-default";

    switch (status) {
      case "Present":
        icon = "✔️";
        className = isConfirmed ? "icon-confirmed-present" : "icon-present";
        break;
      case "Not Present":
        icon = "❌";
        className = isConfirmed ? "icon-confirmed-absent" : "icon-absent";
        break;
      default:
        icon = "⚠️";
        className = "icon-warning";
    }

    return <span className={className}>{icon}</span>;
  };

  const handlePreviousWeek = () => {
    const previousWeek = new Date(currentWeek);
    previousWeek.setDate(previousWeek.getDate() - 7);

    setCurrentWeek(previousWeek);
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentWeek);
    nextWeek.setDate(nextWeek.getDate() + 7);

    const today = new Date();
    const norwayOffset = new Date().getTimezoneOffset() + 120; // Adjust for Norwegian time zone
    today.setMinutes(today.getMinutes() + norwayOffset); // Adjust for Norwegian time zone

    const endOfCurrentWeek = new Date(today);
    endOfCurrentWeek.setDate(today.getDate() - today.getDay() + 7); // End of the current week (Sunday)

    // Allow navigation to next week only if it doesn't surpass the current day
    if (nextWeek <= endOfCurrentWeek && nextWeek <= today) {
      setCurrentWeek(nextWeek);
    }
  };

  const isNextWeekDisabled = () => {
    const today = new Date();
    const norwayOffset = new Date().getTimezoneOffset() + 120; // Norway is UTC+2 during daylight saving, UTC+1 otherwise
    today.setMinutes(today.getMinutes() + norwayOffset); // Adjust for Norwegian time zone

    const endOfCurrentWeek = new Date(today);
    endOfCurrentWeek.setDate(today.getDate() - today.getDay() + 7); // End of the current week (Sunday)

    const startOfNextWeek = new Date(currentWeek);
    startOfNextWeek.setDate(currentWeek.getDate() + 7); // Start of the next week

    return startOfNextWeek > endOfCurrentWeek || startOfNextWeek > today; // Disable if next week is past today
  };

  const handleWeekChange = (date) => {
    const newStart = getStartOfKenyanWeek(date);
    setCurrentWeek(newStart);
  };

  const getStartOfWeek = () => {
    return getStartOfKenyanWeek(currentWeek);
  };

  const getEndOfWeek = () => {
    const endOfWeek = new Date(currentWeek);
    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 7);
    return endOfWeek;
  };

  function getWeekNumber(date) {
    const tempDate = new Date(date.getTime());
    // Ensure the date is in local Nairobi time if needed:
    const localString = tempDate.toLocaleString("en-US", {
      timeZone: "Africa/Nairobi",
    });
    const kenyanDate = new Date(localString);

    // Set kenyanDate to the nearest Thursday (ISO standard for week numbering):
    kenyanDate.setDate(kenyanDate.getDate() + 4 - (kenyanDate.getDay() || 7));
    const yearStart = new Date(kenyanDate.getFullYear(), 0, 1);

    // Calculate ISO week number:
    const weekNumber = Math.ceil(((kenyanDate - yearStart) / 86400000 + 1) / 7);
    return weekNumber;
  }

  const handleLessonCreation = async () => {
    const { day, subjectId, startTime, endTime, report } = newLesson;

    if (
      !day ||
      !subjectId ||
      !startTime ||
      !endTime ||
      !report.trim() ||
      confirmedDays[day]
    ) {
      alert(
        "Please fill in all the required fields: day, subject, start time, end time and lesson report."
      );
      return;
    }

    const isConfirmed = window.confirm(
      `Confirm that you want to create a new lesson on ${day} from ${startTime} to ${endTime}?`
    );

    if (!isConfirmed) return;

    const lessonId = `${subjectId}-${startTime}-${endTime}`;
    const newLessonData = {
      lessonId,
      subjectId,
      startTime,
      endTime,
      report,
      createdBy: userData.name || userData.email || "Unknown User",
      attendance: {},
    };

    // Mark all students as "Present" by default

    students.forEach((student) => {
      newLessonData.attendance[student.id] = "Present";
    });

    const updatedLessons = {
      ...lessons,
      [day]: [...(lessons[day] || []), newLessonData],
    };

    setLessons(updatedLessons);

    const updatedAttendance = { ...attendance };
    students.forEach((student) => {
      updatedAttendance[student.id] = {
        ...updatedAttendance[student.id],
        [day]: {
          ...updatedAttendance[student.id]?.[day],
          [lessonId]: "Present",
        },
      };
    });
    setAttendance(updatedAttendance);

    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", selectedClass);

      // Update lessons in Firestore
      await updateDoc(classDocRef, {
        [`lessons.${day}`]: updatedLessons[day],
      });

      setShowPopup(false);
      setNewLesson({
        subjectId: "",
        day: "",
        startTime: "",
        endTime: "",
        report: "",
      });
    } catch (error) {
      console.error("Error creating lesson:", error);
      alert("Failed to create lesson. Please try again.");
    }
  };

  const CalendarIconInput = ({ value, onClick }) => (
    <span className="calendar-icon-input" onClick={onClick}>
      <FaCalendarAlt size={20} style={{ cursor: "pointer" }} />
    </span>
  );

  const handleConfirmDay = async (day) => {
    const dayLessons = lessons[day] || [];
    let subjectsWithoutAttendance = new Set();

    if (dayLessons.length === 0) {
      const confirmNoLessons = window.confirm(
        "There are no lessons or attendance data for this day. Are you sure you want to confirm?"
      );
      if (!confirmNoLessons) return;
    }

    for (const student of students) {
      for (const lesson of dayLessons) {
        const lessonId = lesson.lessonId;
        const studentAttendance = attendance[student.id]?.[day]?.[lessonId];

        if (!studentAttendance) {
          const subject = subjects.find((sub) => sub.id === lesson.subjectId);
          if (subject) {
            subjectsWithoutAttendance.add(subject.name);
          }
        }
      }
    }

    if (subjectsWithoutAttendance.size > 0) {
      const subjectText =
        subjectsWithoutAttendance.size > 1 ? "subjects" : "subject";
      alert(
        `You have students without registered attendance for the ${subjectText}: ${[
          ...subjectsWithoutAttendance,
        ].join(", ")}. Please ensure all students have a status.`
      );
      return;
    }

    if (dayLessons.length > 0) {
      const confirmMessage = `Confirm that attendance for all lessons on ${day} are added. You will need to contact Management to edit attendance.`;
      if (!window.confirm(confirmMessage)) {
        return;
      }
    }

    const confirmedBy = userData.name || userData.email || "Unknown User";
    const confirmedAt = new Date();

    const updatedConfirmedDays = {
      ...confirmedDays,
      [day]: {
        confirmedBy,
        confirmedAt,
      },
    };
    setConfirmedDays(updatedConfirmedDays);

    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", selectedClass);

      await updateDoc(classDocRef, { confirmedDays: updatedConfirmedDays });

      // Re-fetch immediately to ensure we have the saved Timestamp from Firestore
      const updatedSnap = await getDoc(classDocRef);
      const updatedData = updatedSnap.data();
      setConfirmedDays(updatedData.confirmedDays); // This ensures no "Invalid date"
    } catch (error) {
      console.error("Error confirming day:", error);
    }
  };

  const handleUnconfirmDay = async (day) => {
    if (window.confirm("Are you sure you want to reopen this day?")) {
      const updatedConfirmedDays = { ...confirmedDays };
      delete updatedConfirmedDays[day];

      setConfirmedDays(updatedConfirmedDays);

      try {
        const db = getFirestore();
        const classDocRef = doc(db, "classes", selectedClass);

        await updateDoc(classDocRef, {
          confirmedDays: updatedConfirmedDays,
        });

        console.log(`Day ${day} reopened successfully!`);
      } catch (error) {
        console.error("Error reopening day:", error);
      }
    }
  };

  const formatDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}`;
  };

  function isSameOrBeforeCalendarDay(a, b) {
    // Compare just YYYY-MM-DD, ignoring hours
    return (
      a.getFullYear() < b.getFullYear() ||
      (a.getFullYear() === b.getFullYear() && a.getMonth() < b.getMonth()) ||
      (a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() <= b.getDate())
    );
  }

  const renderConfirmButtonsRow = () => {
    const todayNairobi = toKenyanDate(new Date());
    todayNairobi.setHours(0, 0, 0, 0);

    const weekDays = Array.from({ length: 7 }, (_, index) => {
      const dayDate = new Date(getStartOfWeek());
      dayDate.setDate(dayDate.getDate() + index);
      const dayOfWeek = dayDate.getDay(); // 0 = Sunday, 6 = Saturday
      if (dayOfWeek === 0 || dayOfWeek === 6) return null; // Skip Sunday and Saturday
      return toKenyanDateKey(dayDate);
    }).filter(Boolean); // Remove null entries

    return (
      <tr className="confirm-buttons-row">
        <td>Confirm Attendance</td>
        {weekDays.map((date) => {
          const isDayConfirmed = !!confirmedDays[date];
          const dayDate = new Date(date);
          const isFutureDay = !isSameOrBeforeCalendarDay(dayDate, todayNairobi);

          const timeValue = confirmedDays[date]?.confirmedAt;
          let confirmedTime = "Unknown date";

          if (timeValue) {
            const dateObj =
              timeValue instanceof Timestamp
                ? timeValue.toDate()
                : new Date(timeValue.seconds * 1000);
            confirmedTime = dateObj.toLocaleString("en-GB", {
              timeZone: "Africa/Nairobi",
            });
          }

          return (
            <td key={date}>
              {isDayConfirmed ? (
                <>
                  <span className="confirmed-info">
                    ✅ Confirmed by
                    <br />
                    <i class="fa-regular fa-user"></i>{" "}
                    {confirmedDays[date]?.confirmedBy || "Unknown user"} <br />
                    on {confirmedTime}
                  </span>

                  {isAdmin && (
                    <>
                      <br />
                      <span
                        className="reopen-attendance"
                        onClick={() => handleUnconfirmDay(date)}
                      >
                        <i class="fa-regular fa-square-minus"></i> unconfirm
                      </span>
                    </>
                  )}
                </>
              ) : (
                (isAdmin ||
                  userData?.email === "evans@thewatotolibrary.org") && (
                  <button
                    onClick={() => handleConfirmDay(date)}
                    disabled={isFutureDay}
                    className={`confirm-button ${
                      isFutureDay ? "disabled-button" : ""
                    }`}
                  >
                    Confirm
                  </button>
                )
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderTableBody = () => {
    const weekDays = Array.from({ length: 7 }, (_, index) => {
      const dayDate = new Date(getStartOfWeek());
      dayDate.setDate(dayDate.getDate() + index);
      const dayOfWeek = dayDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) return null; // Skip Sunday and Saturday
      return toKenyanDateKey(dayDate);
    }).filter(Boolean);

    return students.map((student) => (
      <tr key={student.id}>
        <td>
          <i class="fa-solid fa-child"></i> <strong>{student.name}</strong>
        </td>

        {weekDays.map((date) => {
          const dayLessons = lessons[date] || [];

          return (
            <td key={`${student.id}-${date}`}>
              {dayLessons.length > 0
                ? dayLessons.map((lesson) => {
                    const attendanceStatus =
                      attendance[student.id]?.[date]?.[lesson.lessonId] || null;
                    const subject = subjects.find(
                      (sub) => sub.id === lesson.subjectId
                    );

                    return (
                      <div key={lesson.lessonId} className="attendance-item">
                        <span
                          className={`attendance-details ${
                            confirmedDays[date] ? "confirmed-grey-text" : ""
                          }`}
                        >
                          {subject?.abbreviation || "Unknown"}{" "}
                          {lesson.startTime}-{lesson.endTime}
                        </span>{" "}
                        <span
                          onClick={() =>
                            handleToggleDropdown(
                              student.id,
                              lesson.lessonId,
                              date
                            )
                          }
                          className={
                            confirmedDays[date]
                              ? "confirmed-text"
                              : "editable-text"
                          }
                        >
                          {attendanceStatus
                            ? getIcon(attendanceStatus, confirmedDays[date])
                            : getIcon(null)}
                        </span>
                        {!confirmedDays[date] &&
                          showDropdowns[`${student.id}-${lesson.lessonId}`] && (
                            <div
                              ref={(el) =>
                                (dropdownRef.current[
                                  `${student.id}-${lesson.lessonId}`
                                ] = el)
                              }
                              className="dropdown-container"
                            >
                              <select
                                value={attendanceStatus || ""}
                                onChange={(e) =>
                                  handleAttendanceChange(
                                    student.id,
                                    date,
                                    lesson.lessonId,
                                    e.target.value
                                  )
                                }
                                className="attendance-dropdown"
                              >
                                <option value="" disabled>
                                  Select Status
                                </option>
                                {attendanceOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                      </div>
                    );
                  })
                : ""}
            </td>
          );
        })}
      </tr>
    ));
  };

  const handleDeleteLesson = async (lessonId, day) => {
    // Find the lesson based on lessonId
    const lessonToDelete = lessons[day]?.find(
      (lesson) => lesson.lessonId === lessonId
    );

    if (!lessonToDelete) {
      alert("Lesson not found!");
      return;
    }

    // Find the subject name for the lesson
    const subject = subjects.find((sub) => sub.id === lessonToDelete.subjectId);
    const subjectName = subject ? subject.name : "Unknown Subject";

    const isConfirmed = window.confirm(
      `Are you sure you want to delete the lesson ${subjectName} (${lessonToDelete.startTime} - ${lessonToDelete.endTime}) and all associated attendance?`
    );

    if (!isConfirmed) return;

    // Update lessons state immediately for UI feedback
    setLessons((prevLessons) => {
      const updatedLessons = { ...prevLessons };
      updatedLessons[day] = updatedLessons[day].filter(
        (lesson) => lesson.lessonId !== lessonId
      );
      return updatedLessons;
    });

    // Update attendance state immediately for UI feedback
    setAttendance((prevAttendance) => {
      const updatedAttendance = { ...prevAttendance };
      students.forEach((student) => {
        if (updatedAttendance[student.id]?.[day]) {
          delete updatedAttendance[student.id][day][lessonId];
        }
      });
      return updatedAttendance;
    });

    // Update Firestore in the `classes` collection
    try {
      const db = getFirestore();
      const classDocRef = doc(db, "classes", selectedClass);

      const updatedLessons = lessons[day]?.filter(
        (lesson) => lesson.lessonId !== lessonId
      );

      await updateDoc(classDocRef, {
        [`lessons.${day}`]: updatedLessons,
      });

      console.log("Lesson deleted successfully!");
    } catch (error) {
      console.error("Error deleting lesson:", error);
      alert("Failed to delete the lesson. Please try again.");
    }
  };

  const renderTodayLessons = () => {
    const filteredWeekDays = daysOfWeek.filter((_, index) => {
      const dayDate = getStartOfKenyanWeek(currentWeek);
      dayDate.setDate(dayDate.getDate() + index);
      const dayOfWeek = dayDate.getDay(); // 0 = Sunday, 6 = Saturday
      return dayOfWeek !== 0 && dayOfWeek !== 6; // Exclude Sundays and Saturdays
    });

    return (
      <tr className="lightbluebg">
        <td>
          <i className="fa-solid fa-person-chalkboard"></i> Today's Lessons
        </td>
        {filteredWeekDays.map((_, index) => {
          const dayDate = getStartOfKenyanWeek(currentWeek);
          dayDate.setDate(dayDate.getDate() + index);
          const formattedDate = toKenyanDateKey(dayDate);

          const sortedLessons = (lessons[formattedDate] || []).sort((a, b) => {
            const timeA = a.startTime.replace(":", "");
            const timeB = b.startTime.replace(":", "");
            return timeA.localeCompare(timeB); // Sort by startTime
          });

          const isDayConfirmed = !!confirmedDays[formattedDate];

          return (
            <td key={index}>
              {sortedLessons.map((lesson) => {
                const {
                  startTime,
                  endTime,
                  subjectId,
                  lessonId,
                  report,
                  createdBy,
                } = lesson;
                const subject = subjects.find((sub) => sub.id === subjectId);

                return (
                  <div key={lessonId} className="todays-lessons">
                    {!isDayConfirmed && editingLessonId === lessonId ? (
                      <div>
                        <input
                          type="time"
                          className="student-attendance-time-input"
                          value={startTime}
                          onChange={(e) =>
                            handleLessonTimeChange(
                              lessonId,
                              "startTime",
                              e.target.value
                            )
                          }
                        />
                        <input
                          type="time"
                          className="student-attendance-time-input"
                          value={endTime}
                          onChange={(e) =>
                            handleLessonTimeChange(
                              lessonId,
                              "endTime",
                              e.target.value
                            )
                          }
                        />
                        <select
                          value={subjectId}
                          onChange={(e) =>
                            handleLessonSubjectChange(lessonId, e.target.value)
                          }
                        >
                          {subjects.map((subject) => (
                            <option key={subject.id} value={subject.id}>
                              {subject.abbreviation}
                            </option>
                          ))}
                        </select>
                        <button
                          onClick={() => {
                            setEditingLessonId(null);
                            saveUpdatedLessons(); // Save changes to Firestore
                          }}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <div>
                        {isAdmin && !isDayConfirmed && (
                          <>
                            <span
                              className="delete-lesson-icon"
                              onClick={() =>
                                handleDeleteLesson(lessonId, formattedDate)
                              }
                            >
                              <i className="fa-regular fa-square-minus"></i>
                            </span>{" "}
                          </>
                        )}
                        {!isDayConfirmed && (
                          <i
                            class="fa-solid fa-pen-to-square"
                            onClick={() => setEditingLessonId(lessonId)}
                          ></i>
                        )}{" "}
                        <i
                          className="fa-solid fa-envelope-open-text"
                          onMouseEnter={(e) => {
                            const tooltip = e.currentTarget.nextSibling;
                            tooltip.style.display = "block";
                          }}
                          onMouseLeave={(e) => {
                            const tooltip = e.currentTarget.nextSibling;
                            tooltip.style.display = "none";
                          }}
                        ></i>
                        <div className="lesson-report-tooltip">
                          {createdBy && <p>Report by: {createdBy}</p>}

                          {report ? (
                            <i>{report}</i>
                          ) : (
                            <p>No report available</p>
                          )}
                        </div>{" "}
                        <span
                          className={isDayConfirmed ? "confirmed-text" : ""}
                        >
                          {startTime} - {endTime} {subject?.abbreviation}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderView = () => {
    const today = new Date();

    return (
      <div>
        <div className="class-selector">
          <label>Select Class: </label>
          <select
            value={selectedClass}
            onChange={(e) => {
              setSelectedClass(e.target.value);
              localStorage.setItem("selectedClass", e.target.value);
              fetchStudentsForClass(e.target.value);
            }}
          >
            <option value="">Select Class</option>
            {studentClasses
              // .filter((studentClass) => studentClass.status === "active")
              .map((studentClass) => (
                <option key={studentClass.id} value={studentClass.id}>
                  Grade {studentClass.grade} - {studentClass.year}
                </option>
              ))}
          </select>
        </div>

        <br />
        {selectedClass && (
          <div>
            <button
              className="popup-create-button"
              onClick={() => setShowPopup(true)}
            >
              <i class="fa-solid fa-plus"></i> Create Lesson
            </button>
            {showPopup && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <h3>Create Lesson</h3>
                  <table className="register-lesson-popup-table">
                    <tbody>
                      <tr>
                        <td>Day</td>
                        <td>
                          <select
                            value={newLesson.day}
                            onChange={(e) =>
                              setNewLesson({
                                ...newLesson,
                                day: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              Select Day
                            </option>
                            {Array.from({ length: 7 }, (_, index) => {
                              const baseDate =
                                getStartOfKenyanWeek(currentWeek);
                              baseDate.setDate(baseDate.getDate() + index);

                              const dayOfWeek = (baseDate.getDay() + 6) % 7; // Adjust for Monday as the start of the week

                              if (dayOfWeek > 4) return null; // Skip Saturday (5) and Sunday (6)

                              const formattedDate = toKenyanDateKey(baseDate);
                              const displayDate = `${baseDate.getDate()}/${
                                baseDate.getMonth() + 1
                              }`;
                              const isConfirmed =
                                !!confirmedDays[formattedDate];

                              return (
                                <option
                                  key={formattedDate}
                                  value={formattedDate}
                                  disabled={isConfirmed}
                                >
                                  {daysOfWeek[dayOfWeek]} {displayDate}{" "}
                                  {isConfirmed ? "(confirmed)" : ""}
                                </option>
                              );
                            }).filter(Boolean)}

                            {/* Remove null entries */}
                          </select>
                          {confirmedDays[newLesson.day] &&
                            setNewLesson((prev) => ({ ...prev, day: "" }))}
                        </td>
                      </tr>
                      <tr>
                        <td>Subject</td>
                        <td>
                          <select
                            value={newLesson.subjectId}
                            onChange={(e) =>
                              setNewLesson({
                                ...newLesson,
                                subjectId: e.target.value,
                              })
                            }
                          >
                            <option value="" disabled>
                              Select Subject
                            </option>
                            {subjects.map((subject) => (
                              <option key={subject.id} value={subject.id}>
                                {subject.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>From</td>
                        <td>
                          <input
                            type="time"
                            className="student-attendance-time-input"
                            value={newLesson.startTime}
                            onChange={(e) =>
                              setNewLesson({
                                ...newLesson,
                                startTime: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>To</td>
                        <td>
                          <input
                            type="time"
                            className="student-attendance-time-input"
                            value={newLesson.endTime}
                            onChange={(e) =>
                              setNewLesson({
                                ...newLesson,
                                endTime: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2">
                          <textarea
                            placeholder="Briefly, what did you do this lesson?"
                            className="lesson-report-textarea"
                            rows="4"
                            cols="30"
                            value={newLesson.report}
                            onChange={(e) =>
                              setNewLesson({
                                ...newLesson,
                                report: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="popup-buttons">
                    <button
                      className="popup-create-button"
                      onClick={handleLessonCreation}
                    >
                      Create
                    </button>
                    <button
                      className="popup-cancel-button"
                      onClick={() => setShowPopup(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <br />
        {selectedClass && (
          <div className="week-navigation">
            <button onClick={handlePreviousWeek}>Previous Week</button>{" "}
            <span>
              {formatDate(getStartOfWeek())} - {formatDate(getEndOfWeek())}
            </span>{" "}
            <button onClick={handleNextWeek} disabled={isNextWeekDisabled()}>
              Next Week
            </button>
            <ReactDatePicker
              selected={currentWeek}
              onChange={handleWeekChange}
              customInput={<CalendarIconInput />} // Use the custom icon input
              showWeekNumbers
              dateFormat="yyyy-MM-dd"
              calendarStartDay={1} // Set Monday as the start day of the week
              maxDate={today}
            />
          </div>
        )}
        {selectedClass && students.length > 0 && (
          <div className="student-attendance-table-parent">
            <br />
            <div className="student-attendance-table">
              <h3>Attendance for Week {getWeekNumber(getStartOfWeek())}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    {daysOfWeek
                      .map((day, index) => {
                        const dayDate = new Date(getStartOfWeek());
                        dayDate.setDate(dayDate.getDate() + index);
                        const dayOfWeek = dayDate.getDay(); // 0 = Sunday, 6 = Saturday
                        if (dayOfWeek === 0 || dayOfWeek === 6) return null; // Skip Sundays and Saturdays
                        const formattedDate = `${dayDate.getDate()}/${
                          dayDate.getMonth() + 1
                        }`;
                        return (
                          <th key={toKenyanDateKey(dayDate)}>
                            {day} {formattedDate}
                          </th>
                        );
                      })
                      .filter(Boolean)}
                  </tr>
                </thead>
                <tbody>
                  {renderConfirmButtonsRow()}
                  {renderTodayLessons()}
                  {renderTableBody()}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="attendance-page">
          <h2>Student Attendance</h2>
          {loading ? <Loading /> : renderView()}
        </div>
      </div>
    </>
  );
};

export default StudentAttendance;
