import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../AuthProvider";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

const Overview = () => {
  const { userData, simulateUserAccess, stopSimulation, simulatedUser } =
    useAuth();
  const [loading, setLoading] = useState(true);
  const [availableEmails, setAvailableEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");

  const [balance, setBalance] = useState(null); // State for DNB balance
  const [accessToken, setAccessToken] = useState(null); // State for access token

  // Fetch emails for simulation
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const staffCollection = collection(db, "staff");
        const querySnapshot = await getDocs(staffCollection);

        const emails = querySnapshot.docs.map((doc) => ({
          email: doc.id,
          name: doc.data().name || "Unknown",
        }));
        setAvailableEmails(emails);
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    fetchEmails();
  }, []);

  const handleSimulateAccess = () => {
    if (selectedEmail) {
      simulateUserAccess(selectedEmail);
    }
  };

  useEffect(() => {
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  // Redirect to DNB authorization
  const redirectToDNB = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
    const authUrl = `https://api.dnb.no/psd2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=accounts`;
    window.location.href = authUrl;
  };

  // Handle DNB OAuth2 callback
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      fetchAccessToken(code).then((token) => {
        setAccessToken(token);
        fetchAccountBalance(token).then((data) => {
          const account = data.accounts[0]; // Assuming the first account is desired
          setBalance(account.balance.amount);
        });
      });
    }
  }, []);

  // Fetch access token from DNB
  const fetchAccessToken = async (authCode) => {
    const response = await fetch("https://api.dnb.no/psd2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: "authorization_code",
        code: authCode,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      }),
    });
    const data = await response.json();
    return data.access_token;
  };

  // Fetch account balance from DNB
  const fetchAccountBalance = async (token) => {
    const response = await fetch(
      "https://api.dnb.no/psd2/accounts/v1/accounts",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.json();
  };

  return (
    <div className="row2" id="flex">
      <div className="main">
        <h2>The Watoto Program - Intranet</h2>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <p>
              Logged in as: {userData && userData.name} |{" "}
              {userData && userData.description_1}{" "}
              {userData && userData.description_2
                ? `& ${userData.description_2}`
                : ""}
            </p>
          </div>
        )}
        {(userData && userData.email === "magnus@thewatotoprogram.org") ||
        simulatedUser ? (
          <div>
            <br />
            <br />
            <div>
              Simulate user access:{" "}
              <select
                value={selectedEmail}
                onChange={(e) => setSelectedEmail(e.target.value)}
              >
                <option value="" disabled>
                  Select a user
                </option>
                {availableEmails.map((user) => (
                  <option key={user.email} value={user.email}>
                    {user.name} ({user.email})
                  </option>
                ))}
              </select>
              <button onClick={handleSimulateAccess}>Simulate Access</button>
              <button onClick={stopSimulation}>Stop Simulation</button>
            </div>
          </div>
        ) : null}
      </div>
      {/* <div>
        <h1>DNB Integration</h1>
        <button onClick={redirectToDNB}>Connect to DNB</button>
        {balance && (
          <div>
            <h2>Account Balance</h2>
            <p>{balance} NOK</p>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Overview;
