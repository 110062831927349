import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from 'react-router-dom';
import { sharedRoutes } from './SharedRoutes';
import AdminRoutes from './AdminRoutes';
import SponsorRoutes from './SponsorRoutes';
import { Fullscreen } from './components';
import { ProtectedRoute } from './container';
import { useAuth } from './AuthProvider';
import Loading from './components/Loading/Loading';

function App() {
  Fullscreen();

  const location = useLocation();
  const { instance } = useMsal();
  const { isAdmin, isSponsor, isStaff, loadingUser } = useAuth();
  const [msalInitialized, setMsalInitialized] = useState(false); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize(); // Ensure MSAL is initialized
        const response = await instance.handleRedirectPromise(); // Handle the redirect promise
        if (response) {
          console.log("Login/Token acquisition response:", response);
          instance.setActiveAccount(response.account); // Set the active account
        }
        setMsalInitialized(true); // Mark MSAL as initialized
      } catch (error) {
        console.error("Error handling redirect response or initializing MSAL", error);
      }
    };

    initializeMsal();
  }, [instance]);

  if (!msalInitialized) {
    // You can return a loading state or some placeholder until MSAL is initialized
    return <Loading />;
  }

  return (
    <div className="App">
      <Routes basename="/the-watoto-library">
        {sharedRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={React.createElement(route.component, { isAdmin, isSponsor, isStaff })} // Pass isAdmin and isSponsor directly to components
          />
        ))}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isAdmin ? <AdminRoutes /> : <Navigate to="/" replace />}
            </ProtectedRoute>
          }
        />
           {/* <Route
          path="/staff/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isStaff ? <StaffRoutes /> : <Navigate to="/" replace />}
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/sponsor/*"
          element={
            <ProtectedRoute>
              {loadingUser ? null : isSponsor ? <SponsorRoutes /> : <Navigate to="/" replace />}
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
