import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './articles.css';
import App from './App';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msalConfig';
import { BrowserRouter } from 'react-router-dom';
import { ArticlesProvider } from './container/Pages/Articles/ArticlesContext';
import { ProjectsProvider } from './container/Pages/Projects/ProjectsContext';
import { UsersProvider } from './container/Auth/UsersContext';
import { AuthProvider } from './AuthProvider'; // Import the AuthProvider
import { EconomicsProvider } from "./container/Pages/Intranet/Economics/EconomicsContext";
import { PageCountsProvider } from './container/Pages/Intranet/General/PageCountsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <AuthProvider> 
          <UsersProvider>
            <PageCountsProvider>
              <ArticlesProvider>
                <ProjectsProvider>
                  <EconomicsProvider>
                    <App />         
                  </EconomicsProvider>
                </ProjectsProvider>
              </ArticlesProvider>
            </PageCountsProvider>
          </UsersProvider>
        </AuthProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
