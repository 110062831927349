import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { useAuth } from "../../../../AuthProvider";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore"; // Firestore functions
import Loading from "../../../../components/Loading/Loading";
import { db } from "../../../../firebase"; // Firestore config
import "./Reports.css";

const sortReportsByWeek = (reports) => {
  return reports.sort((a, b) => {
    const weekA = parseInt(a.week.replace("Week ", ""));
    const weekB = parseInt(b.week.replace("Week ", ""));
    return weekB - weekA; // Sort in descending order (most recent week first)
  });
};

// Function to check if today is between Thursday (Day 4) and Sunday (Day 0)
const canCreateReport = () => {
  const today = new Date().getDay(); // 0 = Sunday, 4 = Thursday, 5 = Friday
  return today >= 4 || today === 0; // Allow from Thursday (4) to Sunday (0)
};

const Reports = ({ onAdminReportsToReview }) => {
  const { isAdmin, userData } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  // State to store existing reports and loading state
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [yearLoading, setYearLoading] = useState(false);
  const years = [2024, 2025];

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1); // January 1st
    const startDayOfWeek = startDate.getDay(); // Day of the week for January 1st
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1; // Day of the year

    // Calculate the week number, adjusting for the first partial week
    const weekNumber = Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
    return weekNumber;
  };

  const getDaysUntilFriday9PM = (week, reports) => {
    const today = new Date();
    const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 5 = Friday
    const currentTime = today.getHours() * 60 + today.getMinutes(); // Convert time to minutes since 00:00
    const daysUntilFriday = (5 - currentDay + 7) % 7; // 5 is Friday
    const friday9PMTime = 21 * 60; // 21 hours * 60 minutes = 1260 minutes

    const reportExists = reports.some(
      (report) =>
        report.week === `Week ${week}` && report.status === "submitted"
    );

    if (currentDay === 5 && currentTime > friday9PMTime && reportExists) {
      return 7; // If it's after Friday 9 PM and report exists, next report due next Friday
    }

    if ((currentDay === 4 || currentDay === 5) && reportExists) {
      return 7 + daysUntilFriday; // Adjust the due date by adding 7 days
    }

    return daysUntilFriday;
  };

  const currentWeek = getWeekNumber(new Date());
  const daysUntilFriday9PM = getDaysUntilFriday9PM(currentWeek, reports);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (!userData) return;
        setLoading(true);

        let reportsRef = collection(
          db,
          "reports",
          selectedYear.toString(),
          "reports"
        );

        let q;
        if (isAdmin) {
          q = query(reportsRef);
        } else {
          q = query(reportsRef, where("userId", "==", userData.email));
        }

        const querySnapshot = await getDocs(q);
        const fetchedReports = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedReports = sortReportsByWeek(fetchedReports);
        setReports(sortedReports);
        setLoading(false);
        setYearLoading(false);

        if (isAdmin && typeof onAdminReportsToReview === "function") {
          const toReview = sortedReports.filter(
            (report) => report.status === "submitted"
          ).length;
          onAdminReportsToReview(toReview);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
        setLoading(false);
        setYearLoading(false);
      }
    };

    fetchReports();
  }, [userData, isAdmin, onAdminReportsToReview, currentWeek, selectedYear]);

  const handleCreateReport = async (week) => {
    try {
      const yearPath = `${selectedYear}/reports`;
      const newReportRef = await addDoc(
        collection(db, "reports", selectedYear.toString(), "reports"),
        {
          userId: userData.email,
          userName: userData.name,
          week: `Week ${week}`,
          content: "",
          status: "draft",
          submittedAt: null,
          feedback: "",
        }
      );

      navigate(`/reports/${selectedYear}/${newReportRef.id}`);
    } catch (error) {
      console.error("Error creating report:", error);
    }
  };

  // Navigate to edit the existing report
  const handleEditReport = (reportId) => {
    navigate(`/reports/${selectedYear}/${reportId}`);
  };

  const reportExistsForWeek = (week, status) => {
    return reports.some(
      (report) => report.week === `Week ${week}` && report.status === status
    );
  };

  console.log("Current week:", currentWeek);

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    setYearLoading(true); // Start year-specific loading
  };

  const renderYearSelector = () => (
    <div className="year-selector">
      <label htmlFor="year">Select Year: </label>
      <select id="year" value={selectedYear} onChange={handleYearChange}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );

  const renderStaffView = () => {
    const draftExistsForCurrentWeek = reportExistsForWeek(currentWeek, "draft");
    const submittedExistsForCurrentWeek = reportExistsForWeek(
      currentWeek,
      "submitted"
    );
    const awaitingReadExistsForCurrentWeek = reportExistsForWeek(
      currentWeek,
      "awaitingRead"
    );
    const today = new Date();
    const currentDay = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

    const showOverdueMessage =
      !submittedExistsForCurrentWeek &&
      !awaitingReadExistsForCurrentWeek &&
      (currentDay === 6 || currentDay === 0); // Saturday or Sunday

    return (
      <>
        {renderYearSelector()}
        {selectedYear === new Date().getFullYear() && (
          <>
            <p>
              Reports are due every Friday. Next report is due{" "}
              <b>
                {daysUntilFriday9PM === 0 &&
                !reportExistsForWeek(currentWeek, "submitted")
                  ? "today"
                  : daysUntilFriday9PM === 1
                  ? "tomorrow"
                  : `in ${daysUntilFriday9PM} days`}
              </b>
              .
            </p>

            {submittedExistsForCurrentWeek && (
              <p className="report-submitted-p">
                ✅ You have submitted this week's report.
              </p>
            )}
          </>
        )}
        {!awaitingReadExistsForCurrentWeek && showOverdueMessage && (
          <div>
            <p className="overdue-warning">
              ⚠️ You have not submitted the report for Week {currentWeek}.
              Please submit it latest by Sunday.
            </p>
          </div>
        )}
        <br />

        {!draftExistsForCurrentWeek &&
          !submittedExistsForCurrentWeek &&
          !awaitingReadExistsForCurrentWeek &&
          canCreateReport() && (
            <table className="report-table">
              <td>
                <h3>Week {currentWeek}</h3>
              </td>
              <td></td>
              <td>
                <button
                  type="button"
                  className="create-report-btn"
                  onClick={() => handleCreateReport(currentWeek)}
                >
                  Create Report
                </button>
              </td>
            </table>
          )}
        {reports &&
          reports
            .filter((report) => report.status !== "completed")
            .map((report) => {
              return (
                <>
                  <h3>Active Reports</h3>
                  <div key={report.id} className="report-table">
                    <h3>{report.week}</h3>
                    {report.status === "draft" ? (
                      <>
                        <p>
                          <i className="fa-solid fa-user-pen"></i> Draft created
                        </p>
                        <button
                          type="button"
                          className="orange-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Edit Report
                        </button>
                      </>
                    ) : report.status === "submitted" ||
                      report.status === "feedbackDraft" ? (
                      <>
                        <p>
                          <i className="fa-regular fa-clock"></i> Awaiting
                          feedback
                        </p>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View Report
                        </button>
                      </>
                    ) : report.status === "awaitingRead" ? (
                      <>
                        <p>
                          <i class="fa-regular fa-bell"></i> Feedback available
                        </p>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Read Feedback
                        </button>
                      </>
                    ) : report.status === "completed" ? (
                      <>
                        <p>
                          <i class="fa-solid fa-check"></i> Completed
                        </p>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View
                        </button>
                      </>
                    ) : (
                      "invalid"
                    )}
                  </div>
                </>
              );
            })}
        <br />
        <h3>Completed Reports</h3>
        {reports &&
          reports
            .filter((report) => report.status === "completed")
            .map((report) => {
              return (
                <>
                  <table key={report.id} className="report-table">
                    <td>
                      <h3>{report.week}</h3>
                    </td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="view-report-btn"
                        onClick={() => handleEditReport(report.id)}
                      >
                        View Report
                      </button>
                    </td>
                  </table>
                </>
              );
            })}
      </>
    );
  };

  const renderAdminView = () => {
    return (
      <>
        {renderYearSelector()}
        <br />
        <h3>Active Reports</h3>

        {reports && reports.length > 0 ? (
          reports.filter((report) => report.status !== "completed").length >
          0 ? (
            reports
              .filter((report) => report.status !== "completed")
              .filter((report) => report.status !== "draft")

              .map((report) => (
                <table key={report.id} className="report-table">
                  <td>
                    <h3>
                      {report.week} - {report.userName}
                    </h3>
                  </td>

                  {report.status === "draft" ? (
                    <>
                      <td>
                        <i className="fa-solid fa-user-pen"></i> Draft created
                      </td>
                      <td></td>
                    </>
                  ) : report.status === "submitted" ||
                    report.status === "feedbackDraft" ? (
                    <>
                      <td>
                        <i class="fa-solid fa-pencil"></i> Needs feedback
                      </td>
                      <td>
                        <button
                          type="button"
                          className="submit-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          Review Report
                        </button>
                      </td>
                    </>
                  ) : report.status === "awaitingRead" ? (
                    <>
                      <td>
                        <i class="fa-regular fa-clock"></i> Awaiting
                        confirmation
                      </td>
                      <td>
                        <button
                          type="button"
                          className="view-report-btn"
                          onClick={() => handleEditReport(report.id)}
                        >
                          View Report
                        </button>
                      </td>
                    </>
                  ) : (
                    "invalid"
                  )}
                </table>
              ))
          ) : (
            ""
          )
        ) : (
          <p>You have no reports to go through - woho!</p>
        )}
        <br />
        <h3>Completed Reports</h3>
        {reports && reports.length > 0 ? (
          reports.filter((report) => report.status === "completed").length >
          0 ? (
            reports
              .filter((report) => report.status === "completed")

              .map((report) => (
                <table key={report.id} className="report-table">
                  <td>
                    <h3>{report.userName}</h3>
                  </td>

                  <td>
                    <h3>{report.week}</h3>
                  </td>

                  <td>
                    <button
                      type="button"
                      className="view-report-btn"
                      onClick={() => handleEditReport(report.id)}
                    >
                      View Report
                    </button>
                  </td>
                </table>
              ))
          ) : (
            <p>No completed reports</p>
          )
        ) : (
          <p>No reports available</p>
        )}
      </>
    );
  };

  const renderView = () => {
    if (loading) {
      return (
        <div>
          Loading
          <Loading />
        </div>
      );
    }

    if (yearLoading) {
      return <div>Loading reports for {selectedYear}...</div>;
    }

    return isAdmin ? renderAdminView() : renderStaffView();
  };

  return (
    <>
      <div>
        <Locationscroll />
        <div className="row2" id="flex">
          <div className="main">
            <h2>Reports</h2>
            <div className="report-list">
              {loading ? <Loading /> : renderView()}
            </div>

            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
