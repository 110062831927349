// PhoneAuth.js
import React, { useState } from "react";
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return [
    "no", // Norway
    "se", // Sweden
    "dk", // Denmark
    "de", // Germany
    "fr", // France
    "es", // Spain
    "it", // Italy
    "nl", // Netherlands
    "be", // Belgium
    "ch", // Switzerland
    "at", // Austria
    "us", // United States
    "ca", // Canada
    "gb", // United Kingdom
    "fi", // Finland
    "pl", // Poland
    "pt", // Portugal
    "ie", // Ireland
    "ae", // United Arab Emirates
  ].includes(iso2);
});

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const isValid = isPhoneValid(phoneNumber);
  const [verificationSent, setVerificationSent] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(false);

  const navigate = useNavigate();

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved - allow phone number verification
          console.log("reCAPTCHA verified");
        },
        "expired-callback": () => {
          // Response expired - reCAPTCHA reset
          console.log("reCAPTCHA expired, reset");
        },
      }
    );
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setError("");
    setErrorCode("");
    setupRecaptcha();
    setIsProcessing(true);
    const appVerifier = window.recaptchaVerifier;
    try {
      // Create user in database if it doesn't exist
      const db = getFirestore();
      const collectionRef = collection(db, "users");
      const userRef = doc(collectionRef, phoneNumber);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        await setDoc(userRef, {
          phoneNumber: phoneNumber,
          role: "Sponsor",
          created: new Date(),
          lastLogin: new Date(),
        });
      } else {
        // Update last login date
        await setDoc(userRef, { lastLogin: new Date() }, { merge: true });
      }

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      setConfirmationResult(confirmationResult);
      setVerificationSent(true);
      setIsProcessing(false);
    } catch (error) {
      console.error("Error during phone number sign-in:", error);
      setIsProcessing(false);
      setError(
        <>
          Error during phone number sign-in. Please check your credentials and
          try again.
        </>
      );
      setErrorCode(`Ref: ${error.message}`);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    setErrorCode("");
    if (confirmationResult) {
      try {
        const result = await confirmationResult.confirm(otp); // Store result
        console.log("Phone number verified");
        console.log("Logged in successfully!", result.user); // Access user object

        setIsProcessing(false);
        setVerificationSent(false);
        setOtp("");
        setPhoneNumber("");
        navigate("/sponsorship");
      } catch (error) {
        console.error("Error verifying OTP:", error);
        setError(
          <>
            Error verifying OTP.
            <br /> Please check your credentials and try again.
          </>
        );
        setErrorCode(`Ref: ${error.message}`);
      }
    }
  };

  return (
    <div>
      {!verificationSent ? (
        <>
          {" "}
          <form onSubmit={handleSendOtp}>
            <PhoneInput
              defaultCountry="no"
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              countries={countries}
            />
            {!isValid ? (
              <div className="writePhoneNumber">Write your phone number.</div>
            ) : (
              <div className="validPhoneNumber">
                Valid phone number. Click to request code.
              </div>
            )}

            <div id="recaptcha-container"></div>
            <br />

            {isProcessing ? (
              <button
                disabled={!isValid}
                type="submit"
                className="login-container-button"
              >
                Sending{" "}
                <span className="rolling-loading-animation small blue"></span>
              </button>
            ) : (
              <>
                <button
                  disabled={!isValid}
                  type="submit"
                  className="login-container-button"
                >
                  Request Code
                </button>{" "}
              </>
            )}
          </form>
          {error && <p className="error">{error}</p>}
          {errorCode && <p className="errorCode">{errorCode}</p>}
        </>
      ) : (
        <div>
          <p>Enter the verification code sent to your phone.</p>
          <form onSubmit={handleVerifyOtp}>
            <input
              type="text"
              placeholder="Enter Code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {isProcessing ? (
              <button
                type="submit"
                disabled={otp.length === 0}
                className="login-container-button"
              >
                Verify Code
                <span className="rolling-loading-animation small blue"></span>
              </button>
            ) : (
              <>
                <button
                  type="submit"
                  disabled={otp.length === 0}
                  className="login-container-button"
                >
                  Verify Code
                </button>
              </>
            )}
          </form>
          {error && <p className="error">{error}</p>}
          {errorCode && <p className="error">{errorCode}</p>}
        </div>
      )}
    </div>
  );
};

export default PhoneAuth;
