import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  Footer,
  Side,
  Slideshow,
  Section,
  MyLink,
} from "../../../components";
import { doc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useParams } from "react-router-dom";
import NextItems from "../../../components/NextItems/NextItems";
import Loading from "../../../components/Loading/Loading";
import { useProjects } from "./ProjectsContext";

const Project = ({ isAdmin }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { projects, setProjects, projectsLoading } = useProjects(); // Destructure projectsLoading from the context
  const { projectUrl } = useParams();
  const navigate = useNavigate();

  const project = projects.find((project) => project.url === projectUrl);

  const toggleProjectStatus = async () => {
    if (!projectUrl) return; // Guard clause

    const projectRef = doc(db, "whatWeDo", projectUrl); // Construct Firestore document reference based on the URL

    try {
      setIsProcessing(true);
      const docSnapshot = await getDoc(projectRef); // Retrieve document snapshot

      if (docSnapshot.exists()) {
        const currentStatus = docSnapshot.data().status;
        const newStatus = currentStatus === "Published" ? "Draft" : "Published";

        await updateDoc(projectRef, { status: newStatus });

        console.log(`Project status updated to ${newStatus}`);
        setTimeout(() => {
          // Update the status in the context state
          const updatedProjects = projects.map((item) =>
            item.url === projectUrl ? { ...item, status: newStatus } : item
          );
          setProjects(updatedProjects);
          setIsProcessing(false);
        }, 2000);
      } else {
        alert("Project document does not exist");
      }
    } catch (error) {
      alert("Error updating Project status: ", error);
    }
  };

  const handleUpdateSection = async (updatedSectionFields) => {
    const { sectionId, sectionTitle, sectionDescription } =
      updatedSectionFields;

    if (!projectUrl || !sectionId) {
      console.error("Missing Project URL or Section ID.");
      return;
    }

    const projectRef = doc(db, "whatWeDo", projectUrl);
    try {
      const docSnapshot = await getDoc(projectRef);
      if (docSnapshot.exists()) {
        const sections = docSnapshot.data().sections || [];

        // Update the specific section by its ID
        const updatedSections = sections.map((section) => {
          if (section.section_id === sectionId) {
            const updatedSection = { ...section };
            if (sectionTitle !== undefined) {
              updatedSection.section_title = sectionTitle;
            }
            if (sectionDescription !== undefined) {
              updatedSection.section_description = sectionDescription;
            }
            return updatedSection;
          }
          return section;
        });

        // Update Firestore document with updated sections
        await updateDoc(projectRef, { sections: updatedSections });

        console.log("Section updated successfully.");
      } else {
        console.log("Document not found.");
      }
    } catch (error) {
      console.error("Error updating section: ", error);
    }
  };

  const addNewSection = async () => {
    if (!project) return;

    const sections = project.sections || [];

    // Generate a new section ID
    const newSectionId =
      sections.length > 0
        ? sections.reduce(
            (maxId, section) => Math.max(maxId, section.section_id),
            0
          ) + 1
        : 1;

    const newSection = {
      section_id: newSectionId,
      section_title: "New Section Title",
      section_description: "New Section Description",
      section_image: "",
    };

    const updatedSections = [...sections, newSection];

    // Update Firestore
    const projectRef = doc(db, "whatWeDo", projectUrl);
    try {
      await updateDoc(projectRef, {
        sections: updatedSections,
      });
      console.log("Project updated with new section in Firestore");

      // Update local state
      setProjects((prevProjects) =>
        prevProjects.map((item) =>
          item.url === projectUrl
            ? { ...item, sections: updatedSections }
            : item
        )
      );
    } catch (error) {
      console.error("Error adding new section to Firestore: ", error);
    }
  };

  const deleteSection = async (sectionId) => {
    if (!project || !project.sections) return;

    const updatedSections = project.sections
      .filter((section) => section.section_id !== sectionId)
      .map((section, index) => ({ ...section, section_id: index + 1 })); // Reassign section_ids

    // Update Firestore
    const projectRef = doc(db, "whatWeDo", projectUrl);
    try {
      await updateDoc(projectRef, {
        sections: updatedSections,
      });
      console.log("Project updated after section deletion in Firestore");

      // Update local state
      setProjects((prevProjects) =>
        prevProjects.map((item) =>
          item.url === projectUrl
            ? { ...item, sections: updatedSections }
            : item
        )
      );
    } catch (error) {
      console.error("Error deleting section in Firestore: ", error);
    }
  };

  const handleUpdateProject = async (updatedFields) => {
    const { header, description } = updatedFields;

    if (!projectUrl) {
      console.error("Missing Project URL.");
      return;
    }

    const projectRef = doc(db, "whatWeDo", projectUrl);
    try {
      await updateDoc(projectRef, {
        title: header || project.title,
        description: description || project.description,
      });

      console.log("Project main section updated successfully.");

      // Update local state if necessary
      setProjects((prevProjects) =>
        prevProjects.map((item) =>
          item.url === projectUrl
            ? { ...item, title: header, description: description }
            : item
        )
      );
    } catch (error) {
      console.error("Error updating Project main section: ", error);
    }
  };

  const deleteProjectDocument = async () => {
    try {
      const confirmDeletion = window.confirm(
        "Are you sure you want to delete this program? This action cannot be reversed."
      );
      if (!confirmDeletion) return;
      setIsProcessing(true);
      await deleteDoc(doc(db, "whatWeDo", projectUrl));
      // Remove the deleted document from the state
      const updatedProjects = projects.filter(
        (item) => item.url !== projectUrl
      );
      setProjects(updatedProjects);
      setTimeout(() => {
        navigate("/admin/programs");
        setIsProcessing(false);
      }, 2000);
    } catch (error) {
      console.error("Error deleting Project document:", error);
    }
  };

  return (
    <div>
      <Navbar isAdmin={isAdmin} />
      <div className="row" id="flex">
        <Side isProcessing={isProcessing} />
        <div className="main">
          {projectsLoading || !project ? (
            <div className="loading-container">
              <div className="loading-animation"></div>
              <div className="loading-text">
                <Loading />
              </div>
            </div>
          ) : !project ? (
            <div>
              <p>No article found</p> <br />
              <MyLink to="/">
                <u>Go Home</u>
              </MyLink>
            </div>
          ) : (
            <div>
              <MyLink to={`/projects#${projectUrl}`}>
                <div className="div-back">
                  <p className="allNewsLetters">
                    <i className="fa-solid fa-arrow-left"></i>Back to Projects
                  </p>
                </div>
              </MyLink>
              {isAdmin && (
                <>
                  {project && (
                    <>
                      <span
                        className={`status-${project.status?.toLowerCase()}`}
                      >
                        {project.status === "Published" ? (
                          <>
                            {project.status}{" "}
                            <i className="fa fa-check-circle"></i>
                          </>
                        ) : (
                          <>
                            {project.status}{" "}
                            <i className="fa fa-pencil-alt"></i>
                          </>
                        )}
                      </span>
                      <span
                        className="div-readmore status"
                        onClick={toggleProjectStatus}
                      >
                        {" "}
                        {project.status === "Published" ? (
                          <>
                            <i className="fa-solid fa-download"></i> Unpublish
                          </>
                        ) : (
                          <>
                            <i className="fa-solid fa-upload"></i> Publish
                          </>
                        )}
                      </span>
                      <span
                        className="div-readmore status"
                        onClick={deleteProjectDocument}
                      >
                        <i className="fa-solid fa-trash-can"></i> Delete
                      </span>
                    </>
                  )}
                </>
              )}
              {project && (
                <div className="article" id={projectUrl}>
                  {isAdmin && (
                    <p className="small-info">
                      <i class="fa-solid fa-user-pen"></i> You are currently in
                      Edit mode. Click the green "ADMIN" toggle in the
                      navigation bar to see how the article appears to users.
                    </p>
                  )}
                  <Section
                    projectId={project.url} // Pass the correct projectId for "projects"
                    header={project.title}
                    coming={project.coming}
                    instructor={project.instructor}
                    places={project.places}
                    openingHours={project.openingHours}
                    description={project.description}
                    img={project.img}
                    isAdmin={isAdmin}
                    isArticle={false} // This should be false for "projects"
                    onUpdateArticle={handleUpdateProject}
                  />
                  {project?.sections?.map((section) => (
                    <div key={section.section_id}>
                      <Section
                        projectId={project.url} // Correct projectId for "projects"
                        sectionId={section.section_id}
                        sectionTitle={section.section_title}
                        img={section.section_image}
                        sectionDescription={section.section_description}
                        isAdmin={isAdmin}
                        isArticle={false} // This should be false for "projects"
                        onUpdateSection={handleUpdateSection} // Correct handler
                        onDeleteSection={() =>
                          deleteSection(section.section_id)
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              <br />
              {isAdmin && (
                <div className="article">
                  <span
                    onClick={addNewSection}
                    className="div-readmore addSection"
                  >
                    Add New Section <i className="fa-solid fa-plus"></i>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Project;
