import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { Loading } from "../../../../components";
import ManageUsers from "./ManageUsers";
import { useAuth } from "../../../../AuthProvider";
import IncomeExpenses from "./IncomeExpenses";
import "./Sponsorships.css";

const Sponsorships = () => {
  const { isAdmin } = useAuth();
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const [users, setUsers] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [editedRows, setEditedRows] = useState({});
  const [lastUpdatedSponsor, setLastUpdatedSponsor] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [newSponsorship, setNewSponsorship] = useState({
    sponsorId: "",
    studentId: "",
    commitmentAmount: "",
    commitmentPeriod: "",
    currency: "NOK",
    startDate: "",
    status: "Pending",
  });
  const [edits, setEdits] = useState({});
  const [showCanceled, setShowCanceled] = useState(true);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [commitmentDetails, setCommitmentDetails] = useState({
    amount: "",
    period: "",
    currency: "NOK",
    startDate: "",
  });
  const [step, setStep] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [showStudentOptions, setShowStudentOptions] = useState(false);
  const [sortOption, setSortOption] = useState(() => {
    return localStorage.getItem("sponsorshipsSortOption") || "";
  });
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [selectedSponsorship, setSelectedSponsorship] = useState(null);
  const [sponsorStatuses, setSponsorStatuses] = useState({});

  const db = getFirestore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const sponsorshipsSnapshot = await getDocs(
          collection(db, "sponsorships")
        );

        setUsers(
          usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setStudents(
          studentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setSponsors(
          sponsorshipsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const db = getFirestore();
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const studentsData = studentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentsData);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);

  useEffect(() => {
    if (lastUpdatedSponsor) {
      alert(`Sponsorship updated successfully!`);
    }
  }, [lastUpdatedSponsor]);

  const refreshUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const checkSponsorStatuses = async () => {
      const statuses = {};

      for (const student of students) {
        const isSponsored = await hasSponsor(student.id);
        statuses[student.id] = isSponsored;
      }

      setSponsorStatuses(statuses);
    };

    if (students.length > 0) {
      checkSponsorStatuses();
    }
  }, [students]);

  const toggleShowCanceled = () => {
    setShowCanceled((prev) => !prev);
  };

  const assignSponsor = async (wantsStudent) => {
    try {
      const studentName = wantsStudent
        ? students.find((student) => student.id === selectedStudent)?.name
        : null;

      let paymentDay = "1"; // Default to "1"
      if (commitmentDetails.startDate) {
        const date = new Date(commitmentDetails.startDate);
        if (!isNaN(date)) {
          paymentDay = date.getDate().toString();
        }
      }

      // Create a new sponsorship document
      const docRef = await addDoc(collection(db, "sponsorships"), {
        studentName: wantsStudent ? studentName : null,
        sponsorId: selectedSponsor,
        commitmentAmount: commitmentDetails.amount,
        commitmentPeriod: commitmentDetails.period,
        currency: commitmentDetails.currency,
        startDate: commitmentDetails.startDate || null,
        paymentDay: paymentDay,
        status: commitmentDetails.status || "Pending",
        includeInMonthlyIncome: true, // Default to included
      });

      // Update state with the new sponsor
      setSponsors((prevSponsors) => [
        ...prevSponsors,
        {
          id: docRef.id,
          studentName: wantsStudent ? studentName : null,
          sponsorId: selectedSponsor,
          commitmentAmount: commitmentDetails.amount,
          commitmentPeriod: commitmentDetails.period,
          currency: commitmentDetails.currency,
          startDate: commitmentDetails.startDate || null,
          paymentDay: paymentDay,
          status: commitmentDetails.status || "Pending",
          includeInMonthlyIncome: true, // Default to included
        },
      ]);

      alert("Sponsorship created successfully!");

      // Reset all input fields
      setSelectedSponsor(null);
      setCommitmentDetails({
        amount: "",
        period: "",
        currency: "NOK",
        startDate: "",
        status: "",
      });
      setSelectedGrade(null);
      setSelectedStudent(null);
      setShowStudentOptions(false);
      setStep(1);
    } catch (error) {
      console.error("Error assigning sponsor:", error);
      alert("Error assigning sponsor");
      setStep(1);
    }
  };

  const markRowAsEdited = (sponsorId) => {
    setEditedRows((prevEditedRows) => ({
      ...prevEditedRows,
      [sponsorId]: true,
    }));
  };

  useEffect(() => {
    // Fetch students and their sponsor statuses
    const fetchStudentsAndStatuses = async () => {
      try {
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const studentsData = studentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentsData);

        // Fetch sponsor statuses for all students
        const statuses = {};
        for (const student of studentsData) {
          statuses[student.id] = await hasSponsor(student.id);
        }
        setSponsorStatuses(statuses);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching students or sponsor statuses:", error);
        setLoading(false);
      }
    };

    fetchStudentsAndStatuses();
  }, []);

  const hasSponsor = async (studentId) => {
    try {
      const student = students.find((s) => s.id === studentId);
      if (!student) return false;

      const sponsorshipQuery = query(
        collection(db, "sponsorships"),
        where("studentName", "==", student.name),
        where("status", "==", "Active")
      );
      const querySnapshot = await getDocs(sponsorshipQuery);
      return !querySnapshot.empty; // Return true if at least one record exists
    } catch (error) {
      console.error("Error checking sponsorship status:", error);
      return false;
    }
  };

  const handleEditSponsorship = async (sponsorship) => {
    const student = students.find(
      (student) => student.name === sponsorship.studentName
    );

    // Check if the student has an active sponsorship
    const isSponsored = student ? await hasSponsor(student.id) : false;

    setSelectedSponsorship({
      ...sponsorship,
      studentId: student?.id || null,
      isSponsored, // Add sponsorship status
    });

    setShowEditPopup(true);
  };

  const handleStudentChange = async (sponsor, newStudentId) => {
    try {
      const sponsorDoc = doc(db, "sponsorships", sponsor.id);

      if (!newStudentId) {
        // Update the sponsorship to have no student
        await updateDoc(sponsorDoc, {
          studentId: null,
          studentName: "",
        });

        // Update local state
        setSponsors((prevSponsors) =>
          prevSponsors.map((sp) =>
            sp.id === sponsor.id
              ? { ...sp, studentId: null, studentName: "" }
              : sp
          )
        );

        alert("Sponsorship updated to have no student.");
        setShowEditPopup(false);
        return;
      }

      const selectedStudent = students.find(
        (student) => student.id === newStudentId
      );

      if (!selectedStudent) {
        alert("Invalid student selected.");
        return;
      }

      // Update the sponsorship with the new student
      await updateDoc(sponsorDoc, {
        studentId: newStudentId,
        studentName: selectedStudent.name,
      });

      // Update local state
      setSponsors((prevSponsors) =>
        prevSponsors.map((sp) =>
          sp.id === sponsor.id
            ? {
                ...sp,
                studentId: newStudentId,
                studentName: selectedStudent.name,
              }
            : sp
        )
      );

      alert("Student updated successfully!");
      setShowEditPopup(false);
    } catch (error) {
      console.error("Error updating sponsorship:", error);
      alert("Failed to update the sponsorship. Please try again.");
    }
  };

  const handleSaveSponsorship = async () => {
    const {
      sponsorId,
      studentId,
      commitmentAmount,
      commitmentPeriod,
      currency,
      startDate,
      status,
    } = newSponsorship;

    if (!sponsorId || !commitmentAmount || !commitmentPeriod || !currency) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      const db = getFirestore();
      await addDoc(collection(db, "sponsorships"), {
        sponsorId,
        studentId: studentId || null,
        commitmentAmount,
        commitmentPeriod,
        currency,
        startDate: startDate || null,
        status,
      });

      alert("Sponsorship saved successfully.");
      setShowPopup(false);
      setNewSponsorship({
        sponsorId: "",
        studentId: "",
        commitmentAmount: "",
        commitmentPeriod: "",
        currency: "NOK",
        startDate: "",
        status: "Pending",
      });
    } catch (error) {
      console.error("Error saving sponsorship:", error);
      alert("Failed to save sponsorship. Please try again.");
    }
  };

  const handleDelete = async (sponsorshipId) => {
    if (!window.confirm("Are you sure you want to delete this sponsorship?")) {
      return;
    }

    try {
      await deleteDoc(doc(db, "sponsorships", sponsorshipId));
      setSponsors((prevSponsors) =>
        prevSponsors.filter((sponsor) => sponsor.id !== sponsorshipId)
      );
      alert("Sponsorship deleted successfully.");
    } catch (error) {
      console.error("Error deleting sponsorship:", error);
      alert("Failed to delete sponsorship. Please try again.");
    }
  };

  const handleSave = async () => {
    try {
      const sponsorDoc = doc(db, "sponsorships", selectedSponsorship.id);
      await updateDoc(sponsorDoc, selectedSponsorship);

      setSponsors((prevSponsors) =>
        prevSponsors.map((sp) =>
          sp.id === selectedSponsorship.id ? { ...selectedSponsorship } : sp
        )
      );

      setShowEditPopup(false);
      alert("Sponsorship updated successfully!");
    } catch (error) {
      console.error("Error updating sponsorship:", error);
      alert("Failed to update sponsorship. Please try again.");
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Active":
        return {
          color: "#5cb85c",
          fontWeight: "bold",
        };
      case "Pending":
        return {
          color: "#f0ad4e",
          fontWeight: "bold",
        };
    }
  };

  const renderPopup = () => (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Assign Sponsorship</h3>
        <table className="form-table">
          <tbody>
            <tr>
              <td>
                <label>Sponsor:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.sponsorId}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      sponsorId: e.target.value,
                    })
                  }
                >
                  <option value="">Select Sponsor</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name || user.phoneNumber}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label>Student:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.studentId}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      studentId: e.target.value,
                    })
                  }
                >
                  <option value="">No Student</option>
                  {students
                    .sort((a, b) => {
                      const isSponsoredA = sponsorStatuses[a.id] || false;
                      const isSponsoredB = sponsorStatuses[b.id] || false;
                      return isSponsoredA === isSponsoredB
                        ? 0
                        : isSponsoredA
                        ? 1
                        : -1; // Un-sponsored students first
                    })
                    .map((student) => {
                      const isSponsored = sponsorStatuses[student.id]; // Check sponsor status
                      return (
                        <option
                          key={student.id}
                          value={student.id}
                          style={{
                            color: isSponsored ? "red" : "black", // Highlight sponsored students
                          }}
                        >
                          {student.name} - Grade {student.grade || "N/A"}{" "}
                          {isSponsored ? "(has sponsor)" : ""}
                        </option>
                      );
                    })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <label>Currency:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.currency}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      currency: e.target.value,
                    })
                  }
                >
                  <option value="NOK">NOK</option>
                  <option value="KES">KES</option>
                  <option value="USD">USD</option>
                </select>
                <input
                  type="number"
                  placeholder="Amount"
                  value={newSponsorship.commitmentAmount}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      commitmentAmount: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label>Interval:</label>
              </td>
              <td>
                <input
                  type="number"
                  placeholder="Write 1 for monthly"
                  value={newSponsorship.commitmentPeriod}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      commitmentPeriod: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label>Start Date:</label>
              </td>
              <td>
                <input
                  type="date"
                  value={newSponsorship.startDate}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      startDate: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <label>Status:</label>
              </td>
              <td>
                <select
                  value={newSponsorship.status}
                  onChange={(e) =>
                    setNewSponsorship({
                      ...newSponsorship,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="Pending">Pending</option>
                  <option value="Active">Active</option>
                  <option value="Canceled">Canceled</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="popup-buttons">
          <button
            className="popup-create-button"
            onClick={handleSaveSponsorship}
          >
            Save
          </button>
          <button
            className="popup-cancel-button"
            onClick={() => {
              setNewSponsorship({
                sponsorId: "",
                studentId: "",
                commitmentAmount: "",
                commitmentPeriod: "",
                currency: "NOK",
                startDate: "",
                status: "Pending",
              });
              setShowPopup(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Locationscroll />
      <div className="row-2">
        <div className="main">
          {showEditPopup && selectedSponsorship && (
            <div className="edit-popup">
              <div className="popup-content">
                <h3>Edit Sponsorship</h3>
                <table className="edit-sponsorship-table">
                  <tbody>
                    <tr>
                      <td>
                        <label>Sponsor</label>
                      </td>
                      <td>
                        <select
                          value={selectedSponsorship.sponsorId || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              sponsorId: e.target.value,
                            })
                          }
                        >
                          {users.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.name || user.phoneNumber}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Student</label>
                      </td>
                      <td>
                        <select
                          value={selectedSponsorship.studentId || ""}
                          onChange={async (e) => {
                            const selectedId = e.target.value;
                            handleStudentChange(
                              selectedSponsorship,
                              selectedId
                            );
                          }}
                        >
                          <option value="">No student</option>
                          {students
                            .sort((a, b) => {
                              // Sort by sponsorship status: Un-sponsored students (false) come first
                              const isSponsoredA =
                                sponsorStatuses[a.id] || false;
                              const isSponsoredB =
                                sponsorStatuses[b.id] || false;

                              if (isSponsoredA === isSponsoredB) return 0; // If both have the same status, maintain order
                              return isSponsoredA ? 1 : -1; // Un-sponsored students first
                            })
                            .map((student) => {
                              const isSponsored = sponsorStatuses[student.id]; // Preloaded statuses
                              return (
                                <option
                                  key={student.id}
                                  value={student.id}
                                  style={{
                                    color: isSponsored ? "red" : "black", // Highlight sponsored students
                                  }}
                                >
                                  {student.name} | Grade {student.grade},{" "}
                                  {student.current_year}{" "}
                                  {isSponsored ? "(has sponsor)" : ""}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Amount</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={selectedSponsorship.commitmentAmount || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              commitmentAmount: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Period</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={selectedSponsorship.commitmentPeriod || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              commitmentPeriod: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Start Date</label>
                      </td>
                      <td>
                        <input
                          type="date"
                          value={selectedSponsorship.startDate || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              startDate: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>Status</label>
                      </td>
                      <td>
                        <select
                          value={selectedSponsorship.status || ""}
                          onChange={(e) =>
                            setSelectedSponsorship({
                              ...selectedSponsorship,
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="Pending">Pending</option>
                          <option value="Active">Active</option>
                          <option value="Canceled">Canceled</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div className="popup-buttons">
                  <button
                    className="popup-create-button"
                    onClick={() => {
                      handleSave(selectedSponsorship);
                      setShowEditPopup(false);
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="popup-cancel-button"
                    onClick={() => {
                      setShowEditPopup(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="sponsorship-page">
            <h2>Sponsorships</h2>

            {loading ? (
              <Loading />
            ) : (
              <>
                <button
                  className="popup-create-button"
                  onClick={() => setShowPopup(true)}
                >
                  <i class="fa-solid fa-plus"></i> Assign Sponsorship
                </button>

                <br />
                <br />
                <h3>Add / Update users</h3>
                <div className="manage-users-parent">
                  <ManageUsers refreshUsers={refreshUsers} />
                </div>
                <br />

                {showPopup && renderPopup()}

                <br />
                <h3>Current Sponsorships</h3>
                <IncomeExpenses />
                <br />

                <div className="sort-buttons">
                  <button onClick={() => setSortOption("amount")}>
                    Sort by Amount
                  </button>
                  <button onClick={() => setSortOption("paymentDay")}>
                    Sort by Payment Day
                  </button>
                  <button onClick={() => setSortOption("startDate")}>
                    Sort by Start Date
                  </button>
                  <button onClick={toggleShowCanceled}>
                    {showCanceled
                      ? "Hide Canceled Sponsorships"
                      : "Show Canceled Sponsorships"}
                  </button>
                </div>

                <table className="current-sponsorships-table">
                  <thead>
                    <tr>
                      <th>Sponsor</th>
                      <th>Student</th>
                      <th>Amount</th>
                      <th>Monthly Interval</th>
                      <th>Payments On Day</th>
                      <th>Start Date</th>
                      <th>Monthly calculation</th>
                      <th>Status</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sponsors
                      .filter((sponsor) => {
                        if (showCanceled) {
                          return true; // Show all sponsorships, including canceled ones
                        }
                        return (
                          sponsor.status !== "Canceled" ||
                          editedRows[sponsor.id]
                        );
                      })
                      .sort((a, b) => {
                        // First, move Pending sponsors to the top
                        if (a.status === "Pending" && b.status !== "Pending")
                          return -1;
                        if (a.status !== "Pending" && b.status === "Pending")
                          return 1;

                        // Then sort according to sortOption
                        if (sortOption === "amount") {
                          return (
                            (parseFloat(a.commitmentAmount) || 0) -
                            (parseFloat(b.commitmentAmount) || 0)
                          );
                        } else if (sortOption === "paymentDay") {
                          return (
                            (parseInt(a.paymentDay) || 0) -
                            (parseInt(b.paymentDay) || 0)
                          );
                        } else if (sortOption === "startDate") {
                          const dateA = new Date(a.startDate);
                          const dateB = new Date(b.startDate);
                          return dateA - dateB;
                        } else {
                          return 0; // No additional sorting
                        }
                      })

                      .map((sponsor, index) => {
                        const studentName =
                          students.find(
                            (student) => student.id === sponsor.studentId
                          )?.name ||
                          sponsor.studentName ||
                          "";

                        return (
                          <tr
                            key={sponsor.id}
                            className={
                              editedRows[sponsor.id] ? "edited-row" : ""
                            }
                          >
                            <td>
                              {users.find(
                                (user) => user.id === sponsor.sponsorId
                              )?.name || "Unknown"}
                            </td>

                            <td>
                              {editMode ? (
                                <select
                                  value={selectedStudent || ""}
                                  onChange={(e) =>
                                    setSelectedStudent(e.target.value)
                                  }
                                >
                                  <option value="">Select a student</option>
                                  {students.map((student) => (
                                    <option key={student.id} value={student.id}>
                                      {student.name} - Grade {student.grade} -{" "}
                                      {student.current_year}{" "}
                                      {hasSponsor(student.id)
                                        ? "(has sponsor)"
                                        : ""}
                                    </option>
                                  ))}
                                </select>
                              ) : (
                                sponsor.studentName || ""
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <>
                                  <div className="currency-amount-container">
                                    <select
                                      value={sponsor.currency || "NOK"} // Use sponsor's currency
                                      onChange={(e) =>
                                        setSponsors((prevSponsors) =>
                                          prevSponsors.map((sp, idx) =>
                                            idx === index
                                              ? {
                                                  ...sp,
                                                  currency: e.target.value, // Update the sponsor's currency directly
                                                }
                                              : sp
                                          )
                                        )
                                      }
                                    >
                                      <option value="NOK">NOK</option>
                                      <option value="KES">KES</option>
                                      <option value="USD">USD</option>
                                    </select>
                                    <input
                                      type="number"
                                      value={sponsor.commitmentAmount || ""}
                                      onChange={(e) => {
                                        setSponsors((prevSponsors) =>
                                          prevSponsors.map((sp, idx) =>
                                            idx === index
                                              ? {
                                                  ...sp,
                                                  commitmentAmount:
                                                    e.target.value,
                                                }
                                              : sp
                                          )
                                        );
                                        markRowAsEdited(sponsor.id);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                `${sponsor.currency} ${
                                  sponsor.commitmentAmount || "N/A"
                                }`
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <input
                                  type="number"
                                  value={sponsor.commitmentPeriod || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              commitmentPeriod: e.target.value,
                                            }
                                          : sp
                                      )
                                    );
                                    markRowAsEdited(sponsor.id);
                                  }}
                                />
                              ) : (
                                sponsor.commitmentPeriod || "N/A"
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <input
                                  type="number"
                                  value={sponsor.paymentDay || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              paymentDay: e.target.value,
                                            }
                                          : sp
                                      )
                                    );
                                    markRowAsEdited(sponsor.id);
                                  }}
                                  min="1"
                                  max="31"
                                />
                              ) : (
                                sponsor.paymentDay || "1"
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <input
                                  type="date"
                                  value={sponsor.startDate || ""}
                                  onChange={(e) => {
                                    setSponsors((prevSponsors) =>
                                      prevSponsors.map((sp, idx) =>
                                        idx === index
                                          ? {
                                              ...sp,
                                              startDate: e.target.value,
                                            }
                                          : sp
                                      )
                                    );
                                    markRowAsEdited(sponsor.id);
                                  }}
                                />
                              ) : (
                                sponsor.startDate || "N/A"
                              )}
                            </td>
                            <td>
                              {editMode ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={!!sponsor.includeInMonthlyIncome} // Ensure it's a boolean value
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;

                                      // Update the local sponsors state immediately
                                      setSponsors((prevSponsors) =>
                                        prevSponsors.map((sp) =>
                                          sp.id === sponsor.id
                                            ? {
                                                ...sp,
                                                includeInMonthlyIncome:
                                                  isChecked,
                                              }
                                            : sp
                                        )
                                      );

                                      // Mark the row as edited
                                      markRowAsEdited(sponsor.id);

                                      // Update Firestore directly
                                      const sponsorDoc = doc(
                                        db,
                                        "sponsorships",
                                        sponsor.id
                                      );
                                      updateDoc(sponsorDoc, {
                                        includeInMonthlyIncome: isChecked,
                                      })
                                        .then(() => {
                                          console.log(
                                            "Monthly calculation updated successfully."
                                          );
                                        })
                                        .catch((err) => {
                                          console.error(
                                            "Error updating includeInMonthlyIncome:",
                                            err
                                          );
                                          alert(
                                            "Failed to update the monthly calculation. Please try again."
                                          );
                                        });
                                    }}
                                  />
                                </>
                              ) : sponsor.includeInMonthlyIncome ? (
                                <i class="fa-solid fa-check"></i>
                              ) : (
                                "Not included"
                              )}
                            </td>

                            <td>
                              {editMode ? (
                                <select
                                  value={
                                    edits[sponsor.id]?.status ||
                                    sponsor.status ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    setEdits((prevEdits) => ({
                                      ...prevEdits,
                                      [sponsor.id]: {
                                        ...prevEdits[sponsor.id],
                                        status: e.target.value,
                                      },
                                    }));
                                    markRowAsEdited(sponsor.id);
                                  }}
                                >
                                  <option value="Pending">Pending</option>
                                  <option value="Active">Active</option>
                                  <option value="Canceled">Canceled</option>
                                </select>
                              ) : (
                                <span style={getStatusStyle(sponsor.status)}>
                                  {sponsor.status}
                                </span>
                              )}
                            </td>

                            {editMode && (
                              <td>
                                <button
                                  className="popup-create-button"
                                  onClick={() => handleSave(sponsor)}
                                >
                                  Save
                                </button>
                                <button
                                  style={{ marginLeft: "8px", color: "red" }}
                                  className="popup-delete-button"
                                  onClick={() => handleDelete(sponsor.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                            <td>
                              <button
                                className="remark-button blue"
                                onClick={() => handleEditSponsorship(sponsor)}
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sponsorships;
