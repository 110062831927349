import React, { useState } from "react";
import "./SchoolNavbar.css";
import { images } from "../../../../../constants";
import { MyLink } from "../../../../../components";

const SchoolNavbar = ({ setActiveSection }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <div className="school-navbar">
      <div className="school-navbar-main">
        <div className="school-navbar-mobile" onClick={toggleNav}>
          <div>
            <MyLink to="/" onClick={(e) => e.stopPropagation()}>
              <div className="school-navbar-logo">
                <b>The Watoto Academy</b>
                <br />
                <span>Our Sponsorship School</span>
              </div>
            </MyLink>
          </div>

          <div className="school-navbar-toggle">
            {!isNavVisible && <i className="fa-solid fa-bars"></i>}
            {isNavVisible && <i className="fa-solid fa-x"></i>}
          </div>
        </div>

        <div className={`school-navbar-items ${isNavVisible ? "show" : ""}`}>
          <span onClick={() => setActiveSection("home")}>Home</span>
          <span onClick={() => setActiveSection("timetable")}>Timetable</span>
          <span onClick={() => setActiveSection("concept")}>Concept</span>
          <span onClick={() => setActiveSection("community")}>
            Students & Staff
          </span>
          {/* <span onClick={() => setActiveSection("about")}>About</span> */}
          <span onClick={() => setActiveSection("admission")}>Admission</span>
        </div>
        <div className="school-navbar-main school-donate">
          <MyLink to="https://www.thewatotolibrary.org" target="_blank">
            <div>
              <img src={images.TWLlogo} className="school-navbar-img" />
            </div>
          </MyLink>
          <MyLink to="/sponsorship" target="_blank">
            <button className="school-apply-button">Sponsor A Child</button>
          </MyLink>
        </div>
      </div>
    </div>
  );
};

export default SchoolNavbar;
