import {  Article, Articles,  Support, Home, Contact, About, Kibera, Founders, Store, TermsOfSale,
   Innsamling, Login, Sponsorship, Projects, Project, Intranet, Report, Reports, Fundraiser, 
    } from './container';

    import { HomePage } from './container/Pages/School/container'


export const sharedRoutes = [
    { path: '/', component: Home},
    { path: '/article/:articleId', component: Article },
    { path: '/articles', component: Articles },
    { path: '/programs', component: Projects },
    { path: '/:projectUrl', component: Project },
    { path: '/projects', component: Projects },
    { path: '/:projectUrl', component: Project },
    { path: '/contact', component: Contact },
    { path: '/about', component: About },
    { path: '/about/kibera', component: Kibera },
    { path: '/about/founders', component: Founders },
    { path: '/support', component: Support },
    { path: '/donate', component: Support },
    { path: '/store', component: Store },
    { path: '/support/termsofsale', component: TermsOfSale },
    { path: '/innsamling', component: Innsamling },
    { path: '/login', component: Login },
    { path: '/sponsorship', component: Sponsorship },
    { path: '/sponsorship/:student', component: Sponsorship },
    { path: '/reports', component: Reports},
    { path: '/reports/:year/:id', component: Report},
    { path: '/intranet', component: Intranet },
    { path: '/fundraiser', component: Fundraiser },

    {path: '/school', component: HomePage},
];
