import React from "react";
import { images } from "../../../constants";
import "./SponsorshipIncludes.css";

const SponsorshipIncludes = () => {
  const sponsorshipItems = [
    { id: 1, img: images.desk, text: "School fees, desk & chair" },
    { id: 2, img: images.materials, text: "Books & learning materials" },
    { id: 3, img: images.lunch, text: "Daily breakfast & lunch" },
    { id: 4, img: images.uniform, text: "School uniform & shoes" },
  ];

  return (
    <div>
      <br />

      <p>
        <u>All school expenses are included in the sponsorship</u>
      </p>

      <div className="sponsorship-includes">
        {sponsorshipItems.map((item) => (
          <div key={item.id} className="sponsorship-includes__item">
            <img src={item.img} alt={item.text} />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SponsorshipIncludes;
