import React from "react";
import MyLink from "../MyLink/MyLink";

function NextItems({ items, currentIdentifier, identifierType }) {
  const filteredItems = items.filter((item) => item.status === "Published");

  filteredItems.sort((a, b) => a.id - b.id);

  const currentId = identifierType === "id" ? Number(currentIdentifier) : null;

  const sortedItems = filteredItems
    .filter((item) => {
      return identifierType === "id"
        ? item.id !== currentId
        : item.url !== currentIdentifier;
    })
    .sort((a, b) => {
      return identifierType === "id" ? a.id - b.id : a.url.localeCompare(b.url);
    });

  const nextIndex = sortedItems.findIndex((item) =>
    identifierType === "id" ? item.id > currentId : item.url > currentIdentifier
  );
  const prevIndex = nextIndex === -1 ? sortedItems.length : nextIndex;

  const nextArticles = sortedItems.slice(nextIndex, nextIndex + 3);
  const prevArticles = sortedItems.slice(Math.max(0, prevIndex - 3), prevIndex);

  let articlesToShow = [...nextArticles, ...prevArticles].slice(0, 3);

  return (
    <div>
      <br />
      <div
        className={
          identifierType === "id" ? "article-click" : "related-activities"
        }
        style={{ cursor: "default" }}
      >
        <h3>{identifierType === "id" ? "Read More" : "Discover More"}</h3>
        {articlesToShow.map((item) =>
          item.id === 6 && identifierType !== "id" ? ( // Check for program with id 6
            <a
              href="https://thewatotoacademy.org"
              target="_blank"
              rel="noreferrer"
              key={item.id}
            >
              <div className="newsletter-wrapping">
                <span className="pointer">{item.title}</span>
              </div>
            </a>
          ) : (
            <MyLink
              to={
                identifierType === "id"
                  ? `/article/${item.id}`
                  : `/programs/${item.url}`
              }
              key={item.id}
            >
              <div className="newsletter-wrapping">
                <span className="pointer">{item.title}</span>
              </div>
            </MyLink>
          )
        )}
      </div>
    </div>
  );
}

export default NextItems;
