import React, { createContext, useState, useContext, useEffect } from 'react';
import { collection, getDocs, addDoc, setDoc, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';

const ArticlesContext = createContext();

export const useArticles = () => useContext(ArticlesContext);

export const ArticlesProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [articlesLoading, setArticlesLoading] = useState(true);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = () => {
    setArticlesLoading(true);
    const articlesCol = collection(db, 'articles');
    const unsubscribe = onSnapshot(articlesCol, (snapshot) => {
      const articlesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      sortAndSetArticles(articlesList);
      setArticlesLoading(false);
    }, (error) => {
      console.error("Error fetching articles:", error);
      setArticlesLoading(false);
    });

    return unsubscribe; // Return the unsubscribe function for cleanup
  };

  const sortAndSetArticles = (articlesList) => {
    const sortedArticles = sortArticles(articlesList);
    setArticles(sortedArticles);
  };

  const sortArticles = (articlesList) => {
    const monthToNumber = (month) => {
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return months.indexOf(month) + 1;
    };

    return articlesList.sort((a, b) => {
      if (b.year !== a.year) {
        return b.year.localeCompare(a.year);
      } else if (monthToNumber(b.month) !== monthToNumber(a.month)) {
        return monthToNumber(b.month) - monthToNumber(a.month);
      } else {
        return b.day - a.day;
      }
    });
  };

  const addArticle = async (articleData) => {
    // Find the highest current article ID
    const highestId = articles.reduce((maxId, article) => {
      const currentId = parseInt(article.id, 10);
      return currentId > maxId ? currentId : maxId;
    }, 0);

    // Create new article ID by adding 1 to the highest ID
    const newArticleId = highestId + 1;

    try {
      // Use setDoc with doc() to specify the ID
      await setDoc(doc(db, 'articles', newArticleId.toString()), { ...articleData, id: newArticleId });
      await fetchArticles(); // Refresh the articles list after adding a new one
    } catch (error) {
      console.error("Error adding new article:", error);
    }
  };

  const deleteArticle = async (articleId) => {
    try {
      await deleteDoc(doc(db, "articles", articleId));
      await fetchArticles(); // Refresh the articles list after deletion
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  return (
    <ArticlesContext.Provider value={{ articles, articlesLoading, addArticle, deleteArticle }}>
      {children}
    </ArticlesContext.Provider>
  );
};
