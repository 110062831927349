import React, { useState, useEffect, useRef } from "react";
import "./Banner.css";
import { images } from "../../constants";

const Banner = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <div className="banner-container">
      <img
        className="banner-image"
        src={images.schoolPhoto}
        alt="School"
        onLoad={handleImageLoaded}
        style={{ display: imageLoaded ? "block" : "none" }}
      />

      {imageLoaded && (
        <div className="banner-text inline-group">
          <h2>
            <span>A new kind of school for</span>{" "}
            <span>the leaders of tomorrow.</span>
          </h2>
          <p className="inline-group">
            <span>
              We are a 100% free, fully-sponsored junior secondary school
            </span>
            <br />
            <span>embedded in the vibrant community of Kibera.</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default Banner;
