import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Navbar, InfoBar, GoBack, SectionToggle } from "../../components";
import { images } from "../../constants";
import "./Community.css";

const Community = ({ setActiveSection }) => {
  const [genderFilter, setGenderFilter] = useState("all");
  const [studentMembers, setStudentMembers] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const db = getFirestore();
        const studentsSnapshot = await getDocs(collection(db, "students"));
        const studentsData = studentsSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((student) => student.image2 || student.img);
        setStudentMembers(studentsData);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    const fetchStaff = async () => {
      try {
        const db = getFirestore();

        // Fetch all staff
        const staffSnapshot = await getDocs(collection(db, "staff"));
        const staffData = staffSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch all subjects
        const subjectsSnapshot = await getDocs(collection(db, "subjects"));
        const subjectsData = subjectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Create a map of teacherId (email) to subjects
        const teacherSubjectsMap = {};
        subjectsData.forEach((subject) => {
          if (subject.teacherId) {
            if (!teacherSubjectsMap[subject.teacherId]) {
              teacherSubjectsMap[subject.teacherId] = [];
            }
            teacherSubjectsMap[subject.teacherId].push(
              subject.name || "Unnamed Subject"
            );
          }
        });

        // Filter staff with at least one subject and include their subjects
        const staffWithSubjects = staffData
          .filter((staff) => teacherSubjectsMap[staff.email])
          .map((staff) => ({
            ...staff,
            subjects: teacherSubjectsMap[staff.email],
          }));

        // Sort staff by the number of subjects they teach
        staffWithSubjects.sort((a, b) => b.subjects.length - a.subjects.length);

        setStaffMembers(staffWithSubjects);
      } catch (error) {
        console.error("Error fetching staff:", error);
      }
    };

    fetchStudents();
    fetchStaff();
  }, []);

  const renderMember = (member, isStaff = false) => {
    const firstName = member.name.split(" ")[0].toLowerCase();
    if (!isStaff && genderFilter !== "all" && member.gender !== genderFilter) {
      return null;
    }

    return (
      <div className="column" key={member.id}>
        <div className="card" id={firstName}>
          <img
            src={member.image2 || member.img || images.noImage}
            alt={member.name}
            style={{ width: "100%" }}
          />
          <div className="container">
            <h2>{member.name}</h2>
            <p className="title">{member.description_1}</p>

            {isStaff && member.subjects && member.subjects.length > 0 && (
              <>
                <br />
                <p className="title">
                  <u>{member.name.split(" ")[0]} teaches</u> <br />
                  {member.subjects.join(", ")}
                </p>
              </>
            )}
            {member.role === "Team" && (
              <p className="title">
                {member.description_1}
                <br />
                {member.description_2 && `${member.description_2}`}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="community" id="page-container">
      <div className="bodyMargin left content">
        <GoBack setActiveSection={setActiveSection} />
        <h2>Community</h2>
        <p>
          We are a school of great minds, changing the way education has been
          perceived for years in Kibera and in Kenya...
        </p>
        <SectionToggle
          sections={[
            { id: "students", label: "Students" },
            { id: "staff", label: "Staff" },
          ]}
        >
          {(activeSection) => (
            <>
              {activeSection === "students" && (
                <div className="article blueBackground">
                  <h3>Students</h3>
                  {/* <div className="genderBtns">
                    <button
                      onClick={() => setGenderFilter("all")}
                      className={`genderBtns ${
                        genderFilter === "all" ? "active" : ""
                      }`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => setGenderFilter("boy")}
                      className={`genderBtns ${
                        genderFilter === "boy" ? "active" : ""
                      }`}
                    >
                      Boys
                    </button>
                    <button
                      onClick={() => setGenderFilter("girl")}
                      className={`genderBtns ${
                        genderFilter === "girl" ? "active" : ""
                      }`}
                    >
                      Girls
                    </button>
                  </div> */}
                  <div className="flex-container">
                    <div className="row2 twenty">
                      {studentMembers.map((member) => renderMember(member))}
                    </div>
                  </div>
                </div>
              )}
              {activeSection === "staff" && (
                <div className="article blueBackground">
                  <h3>Staff</h3>
                  <div className="flex-container">
                    <div className="row2">
                      {staffMembers.map((member) => renderMember(member, true))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </SectionToggle>
      </div>
    </div>
  );
};

export default Community;
