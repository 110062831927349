import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import Locationscroll from "../../../../components/Locationscroll/Locationscroll";
import { Loading } from "../../../../components";
import { useAuth } from "../../../../AuthProvider";
import "./Students.css";

const Students = () => {
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [students, setStudents] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("");

  // Fetch available classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        if (!userData) {
          setLoading(true);
          return;
        }

        const db = getFirestore();
        const classesSnapshot = await getDocs(collection(db, "classes"));
        const classesData = classesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClasses(classesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching classes:", error);
        setLoading(false);
      }
    };

    fetchClasses();

    // Restore selection from localStorage
    const savedClass = localStorage.getItem("selectedClass");
    const savedSortCriteria = localStorage.getItem("sortCriteria");

    if (savedClass) setSelectedClass(savedClass);
    if (savedSortCriteria) setSortCriteria(savedSortCriteria);
  }, [userData]);

  // Fetch students from the selected class
  useEffect(() => {
    const fetchStudents = async () => {
      if (!selectedClass) return;

      try {
        const db = getFirestore();
        const classRef = doc(db, "classes", selectedClass);
        const classSnap = await getDoc(classRef);

        if (classSnap.exists()) {
          const classData = classSnap.data();
          setStudents(classData.students || []);
        } else {
          setStudents([]);
          console.warn("Class data not found");
        }
      } catch (error) {
        console.error("Error fetching students:", error);
        setStudents([]);
      }
    };

    fetchStudents();
    localStorage.setItem("selectedClass", selectedClass);
  }, [selectedClass]);

  // Handle class selection
  const handleClassChange = (e) => {
    const selectedClassId = e.target.value;
    setSelectedClass(selectedClassId);
  };

  // Handle sorting criteria
  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
    localStorage.setItem("sortCriteria", criteria);
  };

  // Attendance summary
  const getAttendanceSummary = (attendance) => {
    if (!attendance)
      return { present: 0, notPresent: 0, excused: 0, late: 0, total: 0 };

    let present = 0;
    let notPresent = 0;
    let excused = 0;
    let late = 0;

    Object.values(attendance).forEach((daily) => {
      Object.values(daily).forEach((status) => {
        if (status === "Present") present++;
        if (status === "Not Present") notPresent++;
        if (status === "Excused") excused++;
        if (status === "Late") late++;
      });
    });

    return {
      present,
      notPresent,
      excused,
      late,
      total: present + notPresent + excused + late,
    };
  };

  // Grades summary
  const getGradesSummary = (grades) => {
    if (!grades) return "N/A";

    const totalExams = Object.keys(grades).length;
    const totalGrades = Object.values(grades).reduce((sum, exam) => {
      const examTotal = Object.values(exam).reduce(
        (examSum, grade) => examSum + grade,
        0
      );
      return sum + examTotal;
    }, 0);

    return totalExams > 0 ? (totalGrades / totalExams).toFixed(2) : "N/A";
  };

  // Determine if a student is high or low performing
  const getPerformanceClass = (student) => {
    const attendance = getAttendanceSummary(student.attendance);
    const grade = getGradesSummary(student.grades);

    const highAttendance = attendance.present / attendance.total > 0.9;
    const lowAttendance = attendance.notPresent / attendance.total > 0.3;

    const highGrade = parseFloat(grade) > 85;
    const lowGrade = parseFloat(grade) < 50;

    if (highAttendance && highGrade) return "highlight-positive";
    if (lowAttendance || lowGrade) return "highlight-negative";

    return "";
  };

  // Sort students based on criteria
  const sortStudents = (students) => {
    return students.slice().sort((a, b) => {
      const aSummary = getAttendanceSummary(a.attendance);
      const bSummary = getAttendanceSummary(b.attendance);

      const aGrade = parseFloat(getGradesSummary(a.grades)) || 0;
      const bGrade = parseFloat(getGradesSummary(b.grades)) || 0;

      switch (sortCriteria) {
        case "present":
          return bSummary.present - aSummary.present;
        case "notPresent":
          return bSummary.notPresent - aSummary.notPresent;
        case "excused":
          return bSummary.excused - aSummary.excused;
        case "late":
          return bSummary.late - aSummary.late;
        case "highestGrade":
          return bGrade - aGrade;
        default:
          return 0;
      }
    });
  };

  return (
    <>
      <Locationscroll />
      <div className="students-page">
        <h2>Manage Students</h2>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div>
              <label>Select Class: </label>
              <select value={selectedClass} onChange={handleClassChange}>
                <option value="">Select a Class</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    Grade {cls.grade} - {cls.year}{" "}
                    {cls.status === "archived" ? "(archived)" : ""}
                  </option>
                ))}
              </select>{" "}
              ({students.length} students)
            </div>

            {selectedClass && (
              <>
                <br />
                <div className="sort-buttons">
                  <button
                    onClick={() => handleSortChange("present")}
                    className={sortCriteria === "present" ? "active-sort" : ""}
                  >
                    Sort by Present
                  </button>
                  <button
                    onClick={() => handleSortChange("notPresent")}
                    className={
                      sortCriteria === "notPresent" ? "active-sort" : ""
                    }
                  >
                    Sort by Not Present
                  </button>
                  <button
                    onClick={() => handleSortChange("excused")}
                    className={sortCriteria === "excused" ? "active-sort" : ""}
                  >
                    Sort by Excused
                  </button>
                  <button
                    onClick={() => handleSortChange("late")}
                    className={sortCriteria === "late" ? "active-sort" : ""}
                  >
                    Sort by Late
                  </button>
                  <button
                    onClick={() => handleSortChange("highestGrade")}
                    className={
                      sortCriteria === "highestGrade" ? "active-sort" : ""
                    }
                  >
                    Sort by Highest Grade
                  </button>
                </div>

                <table className="students-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Present</th>
                      <th>Not Present</th>
                      <th>Excused</th>
                      <th>Late</th>
                      <th>Total Lessons</th>
                      <th>Average Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortStudents(students).map((student) => {
                      const attendanceSummary = getAttendanceSummary(
                        student.attendance
                      );
                      const averageGrade = getGradesSummary(student.grades);

                      return (
                        <tr
                          key={student.id}
                          className={getPerformanceClass(student)}
                        >
                          <td>{student.name}</td>
                          <td>{attendanceSummary.present}</td>
                          <td>{attendanceSummary.notPresent}</td>
                          <td>{attendanceSummary.excused}</td>
                          <td>{attendanceSummary.late}</td>
                          <td>{attendanceSummary.total}</td>
                          <td>{averageGrade}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Students;
